import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";
import { Mandant, Personaldaten } from "../../../models/PersonaldatenModels";


export type Getters = {
  personaldaten(state: State): Array<Personaldaten>,
  mandanten(state: State): Array<Mandant>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  personaldaten(state) {
    return state.personaldaten;
  },

  mandanten(state) {
    return state.mandanten;
  },
}
