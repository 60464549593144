import { MutationTree } from 'vuex';
import { State } from './state';

import { Vertrag, VertragDokument } from '@/models/VertragModels';

export enum MutationTypes {
  SetVertraege = 'SETVERTRAEGE',
  AddVertrag = 'ADDVERTRAG',
  AddOrUpdateVertrag = 'UPDATEVERTRAG',
  DeleteVertrag = 'DELETEVERTRAG',

  SetVertragDokumente = 'SETVERTRAGDOKUMENTE',
  AddOrUpdateVertragDokument = 'UPDATEVERTRAGDOKUMENTE',
  DeleteVertragDokument = 'DELETEVERTRAGDOKUMENTE',
}

export type Mutations = {
  [MutationTypes.SetVertraege](state: State, data: Array<Vertrag>): void;
  [MutationTypes.AddVertrag](state: State, data: Vertrag): void;
  [MutationTypes.AddOrUpdateVertrag](state: State, data: Vertrag): void;
  [MutationTypes.DeleteVertrag](state: State, data: number): void;

  [MutationTypes.SetVertragDokumente](state: State, data: Array<VertragDokument>): void;
  [MutationTypes.AddOrUpdateVertragDokument](state: State, data: VertragDokument): void;
  [MutationTypes.DeleteVertragDokument](state: State, data: VertragDokument): void;
};

export const mutations: MutationTree<State> & Mutations = {
  //#region Vertraege
  [MutationTypes.SetVertraege](state: State, data: Array<Vertrag>) {
    state.vertraege = data;
    localStorage.setItem('vertraege', JSON.stringify(state.vertraege));
  },

  [MutationTypes.AddVertrag](state: State, data: Vertrag) {
    state.vertraege.push(data);
    localStorage.setItem('vertraege', JSON.stringify(state.vertraege));
  },

  [MutationTypes.AddOrUpdateVertrag](state: State, data: Vertrag) {
    var index = state.vertraege.findIndex((p) => p.id == data.id);
    if (index != -1) {
      state.vertraege.splice(index, 1, data);
    } else {
      state.vertraege.push(data);
    }

    localStorage.setItem('vertraege', JSON.stringify(state.vertraege));
  },

  [MutationTypes.DeleteVertrag](state: State, data: number) {
    var index = state.vertraege.findIndex((p) => p.id == data);
    if (index != -1) {
      state.vertraege.splice(index, 1);
    }

    localStorage.setItem('vertraege', JSON.stringify(state.vertraege));
  },
  //#endregion

  [MutationTypes.SetVertragDokumente](state: State, data: Array<VertragDokument>) {
    const vertragid = data[0].vertragid;
    const index = state.vertraege.findIndex((p) => p.id == vertragid);
    if (index != -1) {
      state.vertraege[index].vertragdokumente = data;
    }

    localStorage.setItem('vertraege', JSON.stringify(state.vertraege));
  },

  [MutationTypes.AddOrUpdateVertragDokument](state: State, data: VertragDokument) {
    const index = state.vertraege.findIndex((p) => p.id == data.vertragid);
    if (index != -1) {
      if (!state.vertraege[index].vertragdokumente) {
        state.vertraege[index].vertragdokumente = [];
      }

      const docIndex = state.vertraege[index].vertragdokumente.findIndex((p) => p.id == data.id);
      if (docIndex != -1) {
        state.vertraege[index].vertragdokumente.splice(docIndex, 1, data);
      } else {
        state.vertraege[index].vertragdokumente.push(data);
      }
    }

    localStorage.setItem('vertraege', JSON.stringify(state.vertraege));
  },

  [MutationTypes.DeleteVertragDokument](state: State, data: VertragDokument) {
    const index = state.vertraege.findIndex((p) => p.id == data.vertragid);
    if (index != -1) {
      const docIndex = state.vertraege[index].vertragdokumente.findIndex((p) => p.id == data.id);
      if (docIndex != -1) {
        state.vertraege[index].vertragdokumente.splice(docIndex, 1);
      }
    }

    localStorage.setItem('vertraege', JSON.stringify(state.vertraege));
  },
};
