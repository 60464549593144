<template>
  <button type="button" class="btn btn-primary" @click="showModal()">Mitarbeiter auswählen</button>

  <!-- Select Mitarbeiter Modal -->
  <div
    class="modal fade"
    :id="'selectMitarbeiterModal-' + index"
    tabindex="-1"
    aria-labelledby="selectMitarbeiterModal"
    aria-hidden="true"
    ref="selectMitarbeiterModalRef"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary py-2">
          <strong class="modal-titel my-auto">Mitarbeiter auswählen</strong>

          <button type="button" class="btn-close btn-sm text-white" @click="closeModal()"></button>
        </div>

        <div class="modal-body modal-body-min-height" id="selectMitarbeiterModalBody">
          <div class="d-flex justify-content-between mb-4 bg-white shadow-sm p-2">
            <div class="d-flex align-items-center">
              <label for="mandant-select" class="me-2"><strong>Mandant:</strong></label>

              <select id="mandant-select" class="form-select" aria-label="Default select example" v-model="selectedMandant" @change="selectedMandantChanged()">
                <option :value="null">Mandant auswählen</option>
                <option v-for="mandant in mandanten" :key="mandant.id" :value="mandant">{{ mandant.man }}</option>
              </select>
            </div>

            <div class="col-4 offset-4">
              <div class="input-group input-group-sm my-auto">
                <input v-model.trim="searchString" type="search" class="form-control" id="searchInput" placeholder="Suche..." />
                <span class="input-group-text">
                  <font-awesome-icon icon="magnifying-glass" class="p-1" />
                </span>
              </div>
            </div>
          </div>

          <div class="row mx-1 mb-2 bg-white shadow-sm">
            <div class="col-2 py-2 table-col">
              <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <strong>Name</strong>

                <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'nameasc'" class="text-secondary table-sort-icon" />
                <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'namedesc'" class="text-secondary table-sort-icon" />

                <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
                  <li class="dropdown-item select-sort" @click="sortBy('nameasc')">
                    <div class="row">
                      <div class="col-2">
                        <font-awesome-icon icon="check" v-show="sortByValue == 'nameasc'" />
                      </div>
                      <div class="col">A - Z</div>
                    </div>
                  </li>
                  <li class="dropdown-item select-sort" @click="sortBy('namedesc')">
                    <div class="row">
                      <div class="col-2">
                        <font-awesome-icon icon="check" v-show="sortByValue == 'namedesc'" />
                      </div>
                      <div class="col">Z - A</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-2 py-2 table-col">
              <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <strong>Vorname</strong>

                <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'vornameasc'" class="text-secondary table-sort-icon" />
                <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'vornamedesc'" class="text-secondary table-sort-icon" />

                <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
                  <li class="dropdown-item select-sort" @click="sortBy('vornameasc')">
                    <div class="row">
                      <div class="col-2">
                        <font-awesome-icon icon="check" v-show="sortByValue == 'vornameasc'" />
                      </div>
                      <div class="col">A - Z</div>
                    </div>
                  </li>
                  <li class="dropdown-item select-sort" @click="sortBy('vornamedesc')">
                    <div class="row">
                      <div class="col-2">
                        <font-awesome-icon icon="check" v-show="sortByValue == 'vornamedesc'" />
                      </div>
                      <div class="col">Z - A</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-8 py-2 table-col">
              <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <strong>Guid</strong>

                <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'guidasc'" class="text-secondary table-sort-icon" />
                <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'guiddesc'" class="text-secondary table-sort-icon" />

                <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
                  <li class="dropdown-item select-sort" @click="sortBy('guidasc')">
                    <div class="row">
                      <div class="col-2">
                        <font-awesome-icon icon="check" v-show="sortByValue == 'guidasc'" />
                      </div>
                      <div class="col">A - Z</div>
                    </div>
                  </li>
                  <li class="dropdown-item select-sort" @click="sortBy('guiddesc')">
                    <div class="row">
                      <div class="col-2">
                        <font-awesome-icon icon="check" v-show="sortByValue == 'guiddesc'" />
                      </div>
                      <div class="col">Z - A</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <LoadingSpinner v-if="loading" text="... Daten werden geladen ..." />

          <div v-else-if="sortedPersonaldaten.length == 0" class="alert alert-info mb-3">Es wurden keine Partner gefunden.</div>

          <div
            v-for="mitarbeiter in sortedPersonaldaten"
            :key="mitarbeiter.id"
            class="row mx-1 mb-2 py-2 bg-white shadow-sm select-mitarbeiter-row"
            @click="selectMitarbeiter(mitarbeiter.guid)"
          >
            <div class="col-2">{{ mitarbeiter.name }}</div>
            <div class="col-2">{{ mitarbeiter.vorname }}</div>
            <div class="col-8">{{ mitarbeiter.guid }}</div>
          </div>

          <div v-if="mandantenErrorMsg != '' || personaldatenErrorMsg != ''" class="alert alert-danger mt-5">
            Es ist ein Fehler beim Laden der Daten aufgetreten. Bite probieren Sie es später erneut.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { PropType, ref, computed, onMounted } from 'vue';
  import { Modal } from 'bootstrap';
  import { useStore } from '@/store';

  import { ActionTypes as PersonalActionTypes } from '@/store/modules/Personaldaten/actions';

  import { GetPersonaldatenRequest, Mandant, Personaldaten } from '@/models/PersonaldatenModels';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  //#endregion

  const { index } = defineProps({
    index: {
      type: Number as PropType<number>,
      default: 0,
    },
  });

  const emit = defineEmits(['select-mitarbeiter']);

  const store = useStore();

  const searchString = ref('');
  const sortByValue = ref('unternehmenasc');
  function sortBy(param: string) {
    sortByValue.value = param;
  }

  const loading = computed(() => store.getters.status.getPersonaldatenLoading || store.getters.status.getMandantenLoading);
  const personaldatenErrorMsg = computed(() => store.getters.status.personaldatenErrorMsg);
  const mandantenErrorMsg = computed(() => store.getters.status.mandantenErrorMsg);

  const mandanten = computed(() => {
    const uniqueMAN = new Set<string>();

    const filteredArray = store.getters.mandanten.reduce((acc: Mandant[], mandant: Mandant) => {
      // Check if 'man' is already in the Set
      if (!uniqueMAN.has(mandant.man)) {
        // If not, add 'man' to the Set and push the object to the accumulator
        uniqueMAN.add(mandant.man);
        acc.push(mandant);
      }

      return acc;
    }, []);

    return filteredArray;
  });

  const selectedMandantStore = ref<Mandant | null>();
  const selectedMandant = computed({
    get() {
      return selectedMandantStore.value;
    },

    set(value) {
      // selectedMandant.value = value
      selectedMandantStore.value = value;
      sessionStorage.setItem('selectedMandant', JSON.stringify(value));
    },
  });

  const personaldaten = computed(() => {
    if (selectedMandant.value != null) {
      return store.getters.personaldaten.filter((p: Personaldaten) => p.mandant == selectedMandant.value?.man);
    } else {
      return store.getters.personaldaten;
    }
  });

  const sortedPersonaldaten = computed(() => {
    return personaldaten.value
      .filter((p: Personaldaten) => {
        const fullName = p.vorname + ' ' + p.name;
        const reverseFullName = p.name + ' ' + p.vorname;
        return (
          fullName.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
          reverseFullName.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
          p.guid.trim().toLowerCase().includes(searchString.value.trim().toLowerCase())
        );
      })
      .sort((a: Personaldaten, b: Personaldaten) => {
        if (sortByValue.value == 'nameasc' || sortByValue.value == 'namedesc') {
          var nameComparison = a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase());
          if (sortByValue.value == 'namedesc') {
            nameComparison = -1 * nameComparison;
          }

          if (nameComparison == 0) {
            var vornameComparison = a.vorname.trim().toLowerCase().localeCompare(b.vorname.trim().toLowerCase());
            return vornameComparison;
          }

          return nameComparison;
        }

        if (sortByValue.value == 'vornameasc' || sortByValue.value == 'vornamedesc') {
          var vornameComparison = a.vorname.trim().toLowerCase().localeCompare(b.vorname.trim().toLowerCase());
          if (sortByValue.value == 'vornamedesc') {
            vornameComparison = -1 * vornameComparison;
          }

          if (vornameComparison == 0) {
            var nameComparison = a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase());
            return nameComparison;
          }

          return vornameComparison;
        }

        if (sortByValue.value == 'guidasc' || sortByValue.value == 'guiddesc') {
          var guidComparison = a.guid.trim().toLowerCase().localeCompare(b.guid.trim().toLowerCase());
          if (sortByValue.value == 'guiddesc') {
            guidComparison = -1 * guidComparison;
          }

          return guidComparison;
        }

        return 0;
      });
  });

  function selectedMandantChanged() {
    getPersonaldaten();
  }

  function getPersonaldaten() {
    store.dispatch(PersonalActionTypes.GetPersonaldaten, new GetPersonaldatenRequest(selectedMandant.value?.man || null, null));
  }

  function selectMitarbeiter(guid: string) {
    emit('select-mitarbeiter', guid);
    closeModal();
  }

  //#region Modal
  const selectMitarbeiterModalRef = ref();

  function closeModal() {
    Modal.getInstance(selectMitarbeiterModalRef.value)?.hide();
  }

  function showModal() {
    if (selectMitarbeiterModalRef.value) {
      new Modal(selectMitarbeiterModalRef.value).show();
    }
  }
  //#endregion

  onMounted(() => {
    if (sessionStorage.getItem('selectedMandant') != undefined) {
      selectedMandant.value = JSON.parse(sessionStorage.selectedMandant) as Mandant;
    } else if (mandanten.value.length > 0) {
      selectedMandant.value = mandanten.value[0];
    } else {
      selectedMandant.value = null;
    }
  });
</script>

<style scoped>
  .table-col:hover {
    background: rgb(216, 217, 222);
    cursor: pointer;
  }

  .table-sort-icon {
    font-size: 0.7em;
  }

  .select-sort:hover {
    cursor: pointer;
  }

  .select-mitarbeiter-row:hover {
    background: #fff4af !important;
    cursor: pointer;
  }
</style>
