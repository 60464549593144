import { ActionContext, ActionTree } from "vuex";

import { RootState } from '@/store';
import { State } from "./state"
import { Mutations, MutationTypes } from "./mutations";

import { Status } from "@/models/CommonModels"


export enum ActionTypes {
  SetStatus = "SETSTATUS",
  ToggleSidebar = "TOGGLESIDEBAR"
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.SetStatus](context: ActionArguments, status: Status): void
  [ActionTypes.ToggleSidebar](context: ActionArguments): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.SetStatus]({commit}, status) {
    commit(MutationTypes.SetStatus, status);
  },

  [ActionTypes.ToggleSidebar]({commit, state}) {
    const status = state.status
    status.sidebarCollapsed = !status.sidebarCollapsed
    commit(MutationTypes.SetStatus, status);
  }
}