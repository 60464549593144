<template>
  <router-link :to="{name: 'Personaldaten'}" class="btn btn-outline-secondary"><font-awesome-icon icon="chevron-left"/> Zurück</router-link>

  <h1>{{ personaldaten?.vorname }} {{ personaldaten?.name }}</h1>
  <p><strong>GUID:</strong> {{ personaldaten?.guid }}</p>

  <hr class="my-4">

  <h2>Verträge</h2>

  <div class="row mb-3 mt-4">
    <div class="col-4">
      <router-link :to="{name: 'EditVertrag', query: {guid: personaldaten?.guid}}" class="btn btn-primary"><font-awesome-icon icon="plus" /> Erstellen</router-link>
    </div>

    <div class="col-4">
      <div class="input-group input-group-sm my-auto">
        <input v-model.trim="searchString" type="search" class="form-control" id="searchInput" placeholder="Suche...">
        <span class="input-group-text">
          <font-awesome-icon icon="magnifying-glass" class="p-1"/>
        </span>
      </div>
    </div>
  </div>

  <LoadingSpinner v-if="getVertraegeLoading" text="... Verträge werden geladen ..." />

  <div v-if="vertragErrorMsg != ''" class="alert alert-danger mt-4">{{ vertragErrorMsg }}</div>



  <div v-if="vertraege.length > 0">
    <div class="row mx-1 mb-2 bg-white shadow-sm">
      <div class="col-4 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Vertrag</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'titelasc'" class="text-secondary table-sort-icon"/>
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'titeldesc'" class="text-secondary table-sort-icon"/>

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('titelasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'titelasc'" />
                </div>
                <div class="col">
                  A - Z
                </div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('titeldesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'titeldesc'" />
                </div>
                <div class="col">
                  Z - A
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-2 my-auto">
        <strong>Mitarbeiter</strong>
      </div>

      <div class="col-2 my-auto">
        <strong>GUID</strong>
      </div>

      <div class="col-2 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Datum</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'datumasc'" class="text-secondary table-sort-icon"/>
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'datumdesc'" class="text-secondary table-sort-icon"/>

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('datumasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'datumasc'" />
                </div>
                <div class="col">
                  A - Z
                </div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('datumdesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'datumdesc'" />
                </div>
                <div class="col">
                  Z - A
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-1 my-auto"><strong>Abschnitte</strong></div>

    </div>

    <VertragRow v-for="vertrag in sortedVertraege" :key="vertrag.id" :vertrag="vertrag" />
  </div>

</template>

<script lang="ts">
import { defineComponent, computed, PropType, ref } from "vue";
import { useStore } from '@/store';
import { useDateConverter } from '@/utils/date_converter';

import { ActionTypes as VertragActionTypes } from '@/store/modules/Vertraege/actions';

import { Personaldaten } from "@/models/PersonaldatenModels"
import { Vertrag } from "@/models/VertragModels";

import VertragRow from "@/components/Vertrag/VertragRow.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default defineComponent({
  name: "DetailsPersonaldatenView",

  components: {
    VertragRow,
    LoadingSpinner
  },

  props: {
    guid: {
      type: String as PropType<string>,
      required: true
    }
  },

  setup(props) {
    const store = useStore();
    const { dateToDateConverter } = useDateConverter();

    const personaldaten = computed(() => store.getters.personaldaten.find((p: Personaldaten) => p.guid == props.guid))

    const searchString = ref("")

    const sortByValue = ref("datumdesc")
    function sortBy (param: string) {
      sortByValue.value = param
    }

    const vertraege = computed(() => store.getters.vertraege.filter((v: Vertrag) => v.personaldatenGuid == props.guid))
    const sortedVertraege = computed(() => {
      return vertraege.value
      .filter((v: Vertrag) => {
        const datum = dateToDateConverter(v.adate)
        return v.titel.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
              datum.trim().toLowerCase().includes(searchString.value.trim().toLowerCase())
      })
      .sort((a: Vertrag, b: Vertrag) => {
        if (sortByValue.value == "titelasc" || sortByValue.value == "titeldesc") {
          var titleComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase())
          if (sortByValue.value == "titeldesc") {
            titleComparison = -1 * titleComparison
          }

          if (titleComparison == 0) {
            var datumComparison = new Date(a.adate).getTime() - new Date(b.adate).getTime()
            return datumComparison;
          }
          
          return titleComparison;
        }

        else if (sortByValue.value == "datumasc" || sortByValue.value == "datumdesc") {
          
          // var datumComparison = a.adate.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase())
          var datumComparison = new Date(a.adate).getTime() - new Date(b.adate).getTime()
          if (sortByValue.value == "datumdesc") {
            datumComparison = -1 * datumComparison
          }
          
          if (datumComparison == 0) {
            var titleComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase())
            return titleComparison
          }
          
          return datumComparison;
        }

        return 0;
      })
    })

    const getVertraegeLoading = computed(() => store.getters.status.getVertraegeLoading)
    const vertragErrorMsg = computed(() => store.getters.status.vertragErrorMsg)

    function loadVertraege() {
      store.dispatch(VertragActionTypes.GetVertraegePersonaldaten, props.guid)
    }

    return {
      getVertraegeLoading,
      vertragErrorMsg,

      personaldaten,

      searchString,
      sortByValue,
      sortBy,
      sortedVertraege,
      vertraege,
      
      loadVertraege
    }
  },

  mounted() {
    if (this.vertraege.length == 0) {
      this.loadVertraege()
    }
  }
})
</script>

<style scoped>
.table-col:hover {
  background: rgb(216, 217, 222);
  cursor: pointer;
}

.table-sort-icon {
  font-size: .7em;
}

.select-sort:hover {
  cursor: pointer;
}
</style>