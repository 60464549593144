<template>
  <button class="btn btn-outline-secondary" @click="goBack()"><font-awesome-icon icon="chevron-left"/> Zurück</button>

  <div v-if="vertragspartner == null" class="alert alert-danger mt-5">
    Ungültige Vertragspartner ID: {{ vertragspartnerid }}
  </div>

  <div v-else>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h1>Partner Details</h1>

      <router-link :to="{name: 'EditVertragspartner', query: {vertragspartnerid: vertragspartnerid}}" class="btn"><font-awesome-icon icon="pen" /></router-link>
    </div>

    <div class="bg-white shadow p-3">
      <div class="row">
        <div class="col-2 fw-bold">Unternehmen:</div>
        <div class="col">{{ vertragspartner.unternehmen }}</div>
      </div>
      <div class="row">
        <div class="col-2 fw-bold">Vorname:</div>
        <div class="col">{{ vertragspartner.vorname }}</div>
      </div>
      <div class="row">
        <div class="col-2 fw-bold">Name:</div>
        <div class="col">{{ vertragspartner.name }}</div>
      </div>
      <div class="row">
        <div class="col-2 fw-bold">Straße:</div>
        <div class="col">{{ vertragspartner.strasse }}</div>
      </div>
      <div class="row">
        <div class="col-2 fw-bold">PLZ:</div>
        <div class="col">{{ vertragspartner.plz }}</div>
      </div>
      <div class="row">
        <div class="col-2 fw-bold">Ort:</div>
        <div class="col">{{ vertragspartner.ort }}</div>
      </div>
      <div class="row">
        <div class="col-2 fw-bold">Land:</div>
        <div class="col">{{ vertragspartner.land }}</div>
      </div>

    </div>

  </div>

</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { Vertragspartner } from '@/models/VertragspartnerModels';

export default defineComponent({
  name: "DetailsVertragspartnerView",

  props: {
    vertragspartnerid: {
      type: String as PropType<string>,
      required: true
    },
  },

  setup(props) {
    const store = useStore()
    const router = useRouter()

    const vertragspartner = computed(() => store.getters.vertragspartner.find((v: Vertragspartner) => v.id == parseInt(props.vertragspartnerid)))

    function goBack() {
      router.push({name: "Vertragspartner"})
    }
    return {
      store,
      vertragspartner,

      goBack
    }
  }
})
</script>