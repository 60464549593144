<template>
  <div v-if="textbausteinid != '0' && textbaustein == null" class="alert alert-danger">
    Der Textbaustein konnte nicht gefunden werden.

    <button type="button" class="btn btn-secondary me-3" @click="cancel()">Zurück</button>
  </div>

  <div class="d-flex justify-content-between">
    <h1 v-if="textbausteinid == '0'">Neuer Textbaustein</h1>
    <h1 v-else>{{ editTextbaustein.name }} Textbaustein bearbeiten</h1>
  </div>

  <hr class="my-4" />

  <SelectVariablen />

  <hr class="my-4" />

  <form @submit.prevent="createOrUpdateTextbaustein">
    <TextbausteinContenBearbeiten :textbaustein="editTextbaustein" />

    <div class="mt-4">
      <p><strong>Abschnitte</strong></p>

      <div
        v-for="(abschnitt, index) in editTextbaustein.abschnitte.sort((a: Textbaustein, b: Textbaustein) => a.position - b.position)"
        :key="index"
        class="row mb-3 border p-2"
      >
        <div v-if="editTextbaustein.abschnitte.length > 1" class="col-1 d-flex flex-column justify-content-start">
          <button type="button" class="btn btn-sm" @click="abschnittPosUp(index)" :disabled="abschnitt.position <= 1">
            <font-awesome-icon icon="chevron-up" />
          </button>
          <button type="button" class="btn btn-sm" @click="abschnittPosDown(index)" :disabled="abschnitt.position == editTextbaustein.abschnitte.length">
            <font-awesome-icon icon="chevron-down" />
          </button>
        </div>

        <div v-else class="col-1"></div>

        <div class="col">
          <TextbausteinContenBearbeiten :textbaustein="abschnitt" :index="index" />

          <div class="mt-3">
            <div
              v-for="(subAbschnitt, subIndex) in abschnitt.abschnitte.sort((a: Textbaustein, b: Textbaustein) => a.position - b.position)"
              :key="subIndex"
              class="row mb-3 border p-2"
            >
              <div v-if="abschnitt.abschnitte.length > 1" class="col-1 d-flex flex-column justify-content-start">
                <button type="button" class="btn btn-sm" @click="subAbschnittPosUp(index, subIndex)" :disabled="subAbschnitt.position <= 1">
                  <font-awesome-icon icon="chevron-up" />
                </button>
                <button
                  type="button"
                  class="btn btn-sm"
                  @click="subAbschnittPosDown(index, subIndex)"
                  :disabled="subAbschnitt.position == abschnitt.abschnitte.length"
                >
                  <font-awesome-icon icon="chevron-down" />
                </button>
              </div>

              <div v-else class="col-1"></div>

              <div class="col">
                <TextbausteinContenBearbeiten :textbaustein="subAbschnitt" :index="subIndex" />
              </div>

              <div class="col-1">
                <button type="button" class="btn" @click="removeSubAbschnitt(index, subIndex)"><font-awesome-icon icon="trash" /></button>
              </div>
            </div>

            <button type="button" class="btn btn-outline-primary" @click="addSubAbschnitt(index)">
              <font-awesome-icon icon="plus" /> Unterabschnitt hinzufügen
            </button>
          </div>
        </div>

        <div class="col-1">
          <button type="button" class="btn" @click="removeAbschnitt(index)"><font-awesome-icon icon="trash" /></button>
        </div>
      </div>

      <button type="button" class="btn btn-primary" @click="addAbschnitt()"><font-awesome-icon icon="plus" /> Abschnitt hinzufügen</button>
    </div>

    <hr class="my-4" />

    <LoadingSpinner v-if="loading" text="... Daten werden geladen ..." />

    <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
      {{ errorMsg }}
      <!-- Es ist ein Fehler beim Laden der Vorlagen aufgetreten. Bite probieren Sie es später erneut. -->
    </div>

    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-secondary me-3" @click="cancel()">Abbrechen</button>
      <button type="submit" class="btn btn-primary">
        <span v-if="textbausteinid != '0'">Speichern</span>
        <span v-else>Erstellen</span>
      </button>
    </div>
  </form>

  <hr class="my-4" />

  <button class="btn btn-danger" @click="deleteTextbaustein()"><font-awesome-icon icon="trash" /> Textbaustein löschen</button>
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, ref, PropType, onMounted } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';

  import { ActionTypes as VorlageActionTypes } from '@/store/modules/Vorlagen/actions';
  import { ActionTypes as VariablenActionTypes } from '@/store/modules/Variablen/actions';

  import TextbausteinContenBearbeiten from '@/components/Vorlagen/TextbausteinContentBearbeiten.vue';
  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import SelectVariablen from '@/components/Vorlagen/SelectVariablen.vue';

  import { Textbaustein } from '@/models/VorlageModels';
  //#endregion

  const props = defineProps({
    textbausteinid: {
      type: String as PropType<string>,
      default: '0',
    },
  });

  const store = useStore();
  const router = useRouter();

  const variablen = computed(() => store.getters.variablen);
  function loadVariablen() {
    store.dispatch(VariablenActionTypes.GetVariablen, undefined);
  }

  const loading = computed(
    () => store.getters.status.createTextbausteinLoading || store.getters.status.updateTextbausteinLoading || store.getters.status.deleteTextbausteinLoading
  );
  const errorMsg = computed(() => store.getters.status.textbausteinErrorMsg);

  const textbaustein = computed(() =>
    store.getters.textbausteine.find((textbaustein: Textbaustein) => textbaustein.id == Number.parseInt(props.textbausteinid))
  );

  const editTextbaustein = ref<Textbaustein>(
    textbaustein.value != null ? (JSON.parse(JSON.stringify(textbaustein.value)) as Textbaustein) : new Textbaustein()
  );

  //#region Abschnitte
  function addAbschnitt() {
    const abschnitt = new Textbaustein();
    abschnitt.position = editTextbaustein.value.abschnitte.length + 1;
    editTextbaustein.value.abschnitte.push(abschnitt);
  }

  function removeAbschnitt(index: number) {
    editTextbaustein.value.abschnitte.splice(index, 1);
    editTextbaustein.value.abschnitte.forEach((abschnitt, i) => {
      abschnitt.position = i + 1;
    });
  }

  function abschnittPosUp(index: number) {
    editTextbaustein.value.abschnitte[index - 1].position++;
    editTextbaustein.value.abschnitte[index].position--;
  }

  function abschnittPosDown(index: number) {
    editTextbaustein.value.abschnitte[index + 1].position--;
    editTextbaustein.value.abschnitte[index].position++;
  }
  //#endregion

  //#region Subabschnitte
  function addSubAbschnitt(index: number) {
    const abschnitt = new Textbaustein();
    abschnitt.position = editTextbaustein.value.abschnitte[index].abschnitte.length + 1;
    console.log(abschnitt.position);
    editTextbaustein.value.abschnitte[index].abschnitte.push(abschnitt);
  }

  function removeSubAbschnitt(index: number, subIndex: number) {
    editTextbaustein.value.abschnitte[index].abschnitte.splice(subIndex, 1);
    editTextbaustein.value.abschnitte[index].abschnitte.forEach((abschnitt, i) => {
      abschnitt.position = i + 1;
    });
  }

  function subAbschnittPosUp(index: number, subIndex: number) {
    editTextbaustein.value.abschnitte[index].abschnitte[subIndex - 1].position++;
    editTextbaustein.value.abschnitte[index].abschnitte[subIndex].position--;
  }

  function subAbschnittPosDown(index: number, subIndex: number) {
    editTextbaustein.value.abschnitte[index].abschnitte[subIndex + 1].position--;
    editTextbaustein.value.abschnitte[index].abschnitte[subIndex].position++;
  }
  //#endregion

  function cancel() {
    editTextbaustein.value = new Textbaustein();
    router.back();
  }

  function sanitizeHTML(htmlString: string): string {
    // Regular expression to remove <script> tags and JavaScript code
    const sanitizedHTML = htmlString.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
    return sanitizedHTML;
  }

  function createOrUpdateTextbaustein() {
    editTextbaustein.value.text = sanitizeHTML(editTextbaustein.value.text);

    editTextbaustein.value.abschnitte.forEach((abschnitt: Textbaustein) => {
      abschnitt.text = sanitizeHTML(abschnitt.text);
    });

    if (textbaustein.value != null) {
      store.dispatch(VorlageActionTypes.UpdateTextbaustein, editTextbaustein.value).then(() => {
        if (errorMsg.value == '') {
          cancel();
        }
      });
    } else {
      store.dispatch(VorlageActionTypes.CreateTextbaustein, editTextbaustein.value).then(() => {
        if (errorMsg.value == '') {
          cancel();
        }
      });
    }
  }

  function deleteTextbaustein() {
    store.dispatch(VorlageActionTypes.DeleteTextbaustein, Number.parseInt(props.textbausteinid)).then(() => {
      if (errorMsg.value == '') {
        cancel();
      }
    });
  }

  onMounted(() => {
    if (variablen.value.length == 0) {
      loadVariablen();
    }
  });
</script>
