<template>
  <button v-if="buttonStyle == 0" type="button" class="btn btn-primary" @click="showModal()">
    <font-awesome-icon icon="plus" /> Neue Druckvorlage
  </button>

  <button v-else type="button" class="btn btn-primary btn-sm" title="Dokument bearbeiten" @click="showModal()">
    <font-awesome-icon icon="pen" />
  </button>

  <!-- Edit DokumentModal -->
  <div
    class="modal fade"
    :id="'editDruckvorlageModal' + index"
    tabindex="-1"
    :aria-labelledby="'editDruckvorlageModal' + index"
    aria-hidden="true"
    ref="editDruckvorlageModalRef"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary py-2">
          <span v-if="editDruckvorlage.id == 0" class="modal-title my-auto" :id="'editDruckvorlageModalLabel' + index">
            Neue Druckvorlage
          </span>

          <span v-else class="modal-title my-auto" :id="'editDruckvorlageModalLabel' + index">
            Druckvorlage bearbeiten
          </span>

          <button
            type="button"
            class="btn-close btn-sm text-white"
            @click="closeModal()"
          ></button>
        </div>

        <div class="modal-body" :id="'editDruckvorlageModalBody' + index">
          <LoadingSpinner v-if="loading" />

          <form v-else @submit.prevent="saveDruckvorlage">

            <div class="mb-3">
              <label :for="'druckvorlageTitel-' + index" class="form-label fw-bold">Titel</label>
              <input type="text" class="form-control" :id="'druckvorlageTitel-' + index" v-model="editDruckvorlage.titel">
            </div>
            
            <div class="mb-3">
              <label :for="'druckvorlageKategorie-' + index" class="form-label fw-bold">Kategorie</label>
              <input type="text" class="form-control" :id="'druckvorlageKategorie-' + index" v-model="editDruckvorlage.kategorie">
            </div>

            <div class="mb-3">
              <label :for="'druckvorlageFileName-' + index" class="form-label fw-bold">Datei Name</label>
              <input type="text" class="form-control" :id="'druckvorlageFileName-' + index" v-model="editDruckvorlage.fileName" disabled>
            </div>

            <div class="mb-3">
              <label for="fileupload" class="form-label">Word-Vorlage (.dotx) auswählen</label>
              <input type="file" class="form-control" id="fileupload" accept=".dotx"  @change="handleFileUpload"/>
            </div>

            <div v-if="fileSizeErrorMessage != ''" class="alert alert-danger mb-3">
              {{ fileSizeErrorMessage }}
            </div>

            <hr class="my-3">

            <div v-if="errorMsg != ''" class="alert alert-danger mb-3">
              Es ist ein Fehler aufgetreten:<br>
              {{ errorMsg }}
            </div>

            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-secondary me-3" @click="closeModal()">Abbrechen</button>
              <button type="submit" class="btn btn-primary" >Speichern</button>
            </div>
          </form>
        </div>

        <div v-if="!loading && editDruckvorlage.id != 0" class="modal-footer justify-content-start">
          <button type="button" class="btn btn-danger" @click="deleteDruckvorlage()"><font-awesome-icon icon="trash" /> Druckvorlage löschen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { Modal } from 'bootstrap';

import { ActionTypes as DruckvorlageActionTypes } from "@/store/modules/Druckvorlagen/actions";

import LoadingSpinner from '../LoadingSpinner.vue';

import { Druckvorlage, CreateDruckvorlageRequest } from '../../models/DruckvorlageModels';

export default defineComponent({
  name: "EditDruckvorlageModal",

  components: {
    LoadingSpinner,
  },

  props: {
    druckvorlage: {
      type: Object as PropType<Druckvorlage>,
      default: new Druckvorlage()
    },

    index: {
      type: Number,
      required: true
    },

    buttonStyle: {
      type: Number as PropType<number>,
      default: 0 // 0 = erstellen; 1 = bearbeiten
    }
  },

  emits: ["deleteDokument"],

  setup(props) {
    const store = useStore();
    
    const loading = computed(() => store.getters.status.createDruckvorlageLoading || store.getters.status.updateDruckvorlageLoading)
    const errorMsg = computed(() => store.getters.status.druckvorlageErrorMsg)

    const editDruckvorlage = ref(JSON.parse(JSON.stringify(props.druckvorlage)) as Druckvorlage)

    const editDruckvorlageModalRef = ref()
    function closeModal() {
      Modal.getInstance(editDruckvorlageModalRef.value)?.hide();
    }
    function showModal() {
      if (editDruckvorlageModalRef.value) {
        editDruckvorlage.value = JSON.parse(JSON.stringify(props.druckvorlage));
        file.value = null;
        new Modal(editDruckvorlageModalRef.value).show();
      }
    }

    const MAX_FILE_SIZE = 3 * 1024 * 1024
    const file = ref<File | null>(null);
    const fileSizeErrorMessage = ref("");

    function handleFileUpload (event: Event) {
      fileSizeErrorMessage.value = "";

      const inputElement = event.target as HTMLInputElement
      const selectedfile = inputElement.files![0]

      
      if (selectedfile) {
        file.value = selectedfile
      }

      if (file.value != null && file.value.size > MAX_FILE_SIZE) {
        fileSizeErrorMessage.value = "Die Anlagengröße überschreitet das erlaubte Maximum. Die maximale Größe ist 3MB."
      }
    }

    function saveDruckvorlage() {
      if (file.value != null && file.value.size > MAX_FILE_SIZE) {
        fileSizeErrorMessage.value = "Die Anlagengröße überschreitet das erlaubte Maximum. Die maximale Größe ist 3MB."
        return
      }

      const requestData: CreateDruckvorlageRequest = {
        id: editDruckvorlage.value.id,
        titel: editDruckvorlage.value.titel,
        kategorie: editDruckvorlage.value.kategorie,
        file: file.value,
        fileName: file.value?.name || ""
      };

      if (editDruckvorlage.value.id != 0) {
        store.dispatch(DruckvorlageActionTypes.UpdateDruckvorlage, requestData)
        .then(() => {
          if (errorMsg.value == "") {
            closeModal();
          }
        })
      } else {
        store.dispatch(DruckvorlageActionTypes.CreateDruckvorlage, requestData)
        .then(() => {
          if (errorMsg.value == "") {
            closeModal();
          }
        })
      }
    }

    function deleteDruckvorlage() {
      closeModal();
      // emit("deleteDokument", props.druckvorlage.id);
      store.dispatch(DruckvorlageActionTypes.DeleteDruckvorlage, props.druckvorlage.id)
    }

    return {
      loading,
      errorMsg,
      fileSizeErrorMessage,

      editDruckvorlage,

      editDruckvorlageModalRef,
      closeModal,
      showModal,

      handleFileUpload,
      saveDruckvorlage,
      deleteDruckvorlage,
    }
  },
})
</script>

<style scoped>
.modal-content {
  background: rgb(242, 243, 248);
}

/* .modal-body {
  background: rgb(242, 243, 248);
}

.modal-footer {
  background: rgb(242, 243, 248);
} */
</style>