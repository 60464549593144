import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";
import { Druckvorlage } from "@/models/DruckvorlageModels";


export type Getters = {
  druckvorlagen(state: State): Array<Druckvorlage>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  druckvorlagen(state) {
    return state.druckvorlagen;
  },
}
