import { ActionContext, ActionTree } from 'vuex';
import PersonalApiService from '@/services/PersonalApiService';
import { Actions as CommonActions, ActionTypes as CommonActionTypes } from '../Common/actions';

import { RootState } from '@/store';
import { State } from './state';
import { Mutations, MutationTypes } from './mutations';

import { ErrorResponseData } from '@/models/CommonModels';
import { GetPersonaldatenRequest, Mandant, Personaldaten } from '@/models/PersonaldatenModels';

export enum ActionTypes {
  GetPersonaldaten = 'GETPERSONALDATEN',
  GetMandanten = 'GETMANDANTEN',
}

type ActionArguments = Omit<ActionContext<State, RootState>, 'commit'> & {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

export type Actions = {
  [ActionTypes.GetPersonaldaten](context: ActionArguments, data: GetPersonaldatenRequest): Promise<void>;
  [ActionTypes.GetMandanten](context: ActionArguments): Promise<void>;
};

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetPersonaldaten]({ commit, dispatch, rootGetters }: ActionArguments, data: GetPersonaldatenRequest) {
    const status = rootGetters.status;
    status.getPersonaldatenLoading = true;
    status.personaldatenErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return PersonalApiService.getPersonaldaten(data)
      .then((res) => {
        const personaldaten: Array<Personaldaten> = res.data;
        commit(MutationTypes.SetPersonaldaten, personaldaten);
      })
      .catch((error) => {
        var errorresponse = (error.response?.data as ErrorResponseData) || null;
        status.personaldatenErrorMsg = errorresponse.title;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getPersonaldatenLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.GetMandanten]({ commit, dispatch, rootGetters }: ActionArguments) {
    const status = rootGetters.status;
    status.getMandantenLoading = true;
    status.mandantenErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return PersonalApiService.getMandanten()
      .then((res) => {
        const mandanten: Array<Mandant> = res.data;
        commit(MutationTypes.SetMandanten, mandanten);
      })
      .catch((error) => {
        var errorresponse = (error.response?.data as ErrorResponseData) || null;
        status.mandantenErrorMsg = errorresponse.title;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getMandantenLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },
};
