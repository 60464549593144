export class Personaldaten {
  guid: string;
  name: string;
  vorname: string;
  strasse: string;
  plz: string;
  ort: string;
  land: string;
  tel: string;
  fax: string;
  mobil: string;
  email: string;
  telg: string;
  faxg: string;
  mobilg: string;
  emailg: string;
  mandant: string;
  abrkreis: string;
  persnr: string;
  kostenst: string;
  statusupdate: number;
  statusdelte: number;
  vertnr: number;
  gebdat: Date;
  archivname: string;
  archivmandant: string;
  krankenkasse: string;
  svnummer: string;
  domainusername: string;
  cryptuserpassword: string;
  id: number;
  geschlecht: string;
  information: string;
  text: string;
  idfnummer: string;
  vznummer: string;
  sprache: string;
  landid: string;
  orgeinheit: string;
  username: string;
  titel: string;
  administrator: string;
  krankenkassebetriebnr: string;
  externepersnr: string;
  wochenarbeitsstunden: number;
  unbezahlteueberstunden: number;
  jahresurlaub: number;
  pausezeit: number;
  projektid: number;
  iban: string;
  bic: string;
  bankname: string;
  plz2: string;
  strasse2: string;
  ort2: string;
  rentenbeginn: Date;
  krankenkasseprivat: string;
  adate: Date;
  mdate: Date;
  vdate: Date;
  edate: Date;
  notfallname: string;
  notfalltelefon: string;
  chatadresse: string;
  jobticket: string;

  constructor () {
    this.guid = ""
    this.name = ""
    this.vorname = ""
    this.strasse = ""
    this.plz = ""
    this.ort = ""
    this.land = ""
    this.tel = ""
    this.fax = ""
    this.mobil = ""
    this.email = ""
    this.telg = ""
    this.faxg = ""
    this.mobilg = ""
    this.emailg = ""
    this.mandant = ""
    this.abrkreis = ""
    this.persnr = ""
    this.kostenst = ""
    this.statusupdate = 0
    this.statusdelte = 0
    this.vertnr = 0
    this.gebdat = new Date()
    this.archivname = ""
    this.archivmandant = ""
    this.krankenkasse = ""
    this.svnummer = ""
    this.domainusername = ""
    this.cryptuserpassword = ""
    this.id = 0
    this.geschlecht = ""
    this.information = ""
    this.text = ""
    this.idfnummer = ""
    this.vznummer = ""
    this.sprache = ""
    this.landid = ""
    this.orgeinheit = ""
    this.username = ""
    this.titel = ""
    this.administrator = ""
    this.krankenkassebetriebnr = ""
    this.externepersnr = ""
    this.wochenarbeitsstunden = 0
    this.unbezahlteueberstunden = 0
    this.jahresurlaub = 0
    this.pausezeit = 0
    this.projektid = 0
    this.iban = ""
    this.bic = ""
    this.bankname = ""
    this.plz2 = ""
    this.strasse2 = ""
    this.ort2 = ""
    this.rentenbeginn = new Date()
    this.krankenkasseprivat = ""
    this.adate = new Date()
    this.mdate = new Date()
    this.edate = new Date()
    this.vdate = new Date()
    this.notfallname = ""
    this.notfalltelefon = ""
    this.chatadresse = ""
    this.jobticket = ""
  }
}

export class GetPersonaldatenRequest {
  mandant: string | null;
  name: string | null;

  constructor(mandant: string | null, name: string | null) {
    this.mandant = mandant;
    this.name = name;
  }
}

export class Mandant {
  id: number
  man: string
  ak: string
  archivemandant: string
  archivepersonal: string
  archiveabrechnung: string
  tarismandant: string
  mailabsender: string
  gesellschaft: string
  fehlzeiten: number
  arbeitszeiten: number
  archiveschulung: string
  stundenlohn: number
  impressum: string
  iban: string
  mailempfaenger: string

  constructor() {
    this.id = 0;
    this.man = "";
    this.ak = "";
    this.archivemandant = "";
    this.archivepersonal = "";
    this.archiveabrechnung = "";
    this.tarismandant = "";
    this.mailabsender = "";
    this.gesellschaft = "";
    this.fehlzeiten = 0;
    this.arbeitszeiten = 0;
    this.archiveschulung = "";
    this.stundenlohn = 0;
    this.impressum = "";
    this.iban = "";
    this.mailempfaenger = "";
  }
}