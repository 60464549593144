<template>
  <div class="d-flex justify-content-center align-items-center mt-5">
    <LoadingSpinner v-if="loading" :text="'... Daten werden überprüft ...'"/>

    <div v-else class="d-flex-col justify-content-center">
      <div class="mb-5 text-center">
        <h1>1.A Vertragseditor</h1>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from '@/store'

import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default defineComponent({
  name: "Home",

  components: {
    LoadingSpinner
  },

  setup () {
    const store = useStore();
    const loading = computed(() => store.getters.status.loading)

    return {
      store,
      loading,
    }
  }
})
</script>