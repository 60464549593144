<template>
  <div class="d-flex justify-content-between">
    <h1>Variablen</h1>

    <button class="btn" @click="loadVariablen()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="row my-4">
    <div class="col-4">
      <EditVariableModal />
    </div>

    <div class="col-4 my-auto">
      <div class="input-group input-group-sm my-auto">
        <input v-model.trim="searchString" type="search" class="form-control" id="searchInput" placeholder="Suche..." />
        <span class="input-group-text">
          <font-awesome-icon icon="magnifying-glass" class="p-1" />
        </span>
      </div>
    </div>

    <div v-if="!loading && variablen.length > 0" class="col-4 text-end my-auto">
      <div class="dropdown">
        <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="select-herkunft-button" data-bs-toggle="dropdown" aria-expanded="false">
          Herkunft auswählen...
        </button>
        <div class="dropdown-menu p-0" aria-labelledby="select-herkunft-button">
          <div
            v-for="(table, index) in herkunft.sort((a: string, b: string) => a.localeCompare(b))"
            :key="index"
            class="dropdown-checkbox px-1 py-2 border-bottom"
          >
            <div class="form-check">
              <input class="form-check-input" type="checkbox" :id="'selected-herkunft-item-' + index" v-model="selectedHerkunft" :value="table" />
              <label class="form-check-label" :for="'selected-herkunft-item-' + index">{{ table != '' ? table : 'Unbekannt' }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <LoadingSpinner v-if="loading" text="... Daten werden geladen ..." />

  <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
    <!-- Es ist ein Fehler beim Laden der Variablen aufgetreten. Bite probieren Sie es später erneut. -->
    {{ errorMsg }}
  </div>

  <div v-else-if="!loading && errorMsg == '' && variablen.length == 0" class="alert alert-info mt-5">Es wurden keine Variablen gefunden.</div>

  <div v-if="!loading && variablen.length > 0">
    <div class="row mx-1 mb-2 bg-white shadow-sm">
      <div class="col-3 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong class="me-1">Titel</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'titelasc'" class="text-secondary table-sort-icon" />
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'titeldesc'" class="text-secondary table-sort-icon" />

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('titelasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'titelasc'" />
                </div>
                <div class="col">A - Z</div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('titeldesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'titeldesc'" />
                </div>
                <div class="col">Z - A</div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-3 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong class="me-1">Variable</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'platzhalterasc'" class="text-secondary table-sort-icon" />
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'platzhalterdesc'" class="text-secondary table-sort-icon" />

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('platzhalterasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'platzhalterasc'" />
                </div>
                <div class="col">A - Z</div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('platzhalterdesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'platzhalterdesc'" />
                </div>
                <div class="col">Z - A</div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-2 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong class="me-1">Herkunft</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'herkunftasc'" class="text-secondary table-sort-icon" />
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'herkunftdesc'" class="text-secondary table-sort-icon" />

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('herkunftasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'herkunftasc'" />
                </div>
                <div class="col">A - Z</div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('herkunftdesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'herkunftdesc'" />
                </div>
                <div class="col">Z - A</div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-2 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong class="me-1">Feld</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'feldasc'" class="text-secondary table-sort-icon" />
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'felddesc'" class="text-secondary table-sort-icon" />

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('feldasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'feldasc'" />
                </div>
                <div class="col">A - Z</div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('felddesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'felddesc'" />
                </div>
                <div class="col">Z - A</div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-2 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong class="me-1">Type</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'typeasc'" class="text-secondary table-sort-icon" />
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'typedesc'" class="text-secondary table-sort-icon" />

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('typeasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'typeasc'" />
                </div>
                <div class="col">A - Z</div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('typedesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'typedesc'" />
                </div>
                <div class="col">Z - A</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <VariableRow v-for="variable in sortedVariablen" :key="variable.id" :variable="variable" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref } from 'vue';
  import { useStore } from 'vuex';

  import { ActionTypes as VariablenActionTypes } from '@/store/modules/Variablen/actions';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import EditVariableModal from '@/components/Vorlagen/EditVariableModal.vue';
  import VariableRow from '@/components/Vorlagen/VariableRow.vue';
  import { Variable } from '@/models/VorlageModels';

  export default defineComponent({
    name: 'Textvariablen',

    components: {
      LoadingSpinner,
      EditVariableModal,
      VariableRow,
    },

    setup() {
      const store = useStore();

      const loading = computed(() => store.getters.status.getVariablenLoading || store.getters.status.deleteVariablenLoading);
      const errorMsg = computed(() => store.getters.status.variablenErrorMsg);

      const variablen = computed(() => store.getters.variablen);

      const searchString = ref('');
      const selectedHerkunft = ref<Array<string>>([]);
      const herkunft = computed(() => {
        return variablen.value.map((variable: Variable) => variable.herkunft).filter((value: any, index: any, self: any) => self.indexOf(value) === index);
      });

      const sortByValue = ref('titelasc');
      function sortBy(param: string) {
        sortByValue.value = param;
      }
      const sortedVariablen = computed(() => {
        return variablen.value
          .filter((v: Variable) => (selectedHerkunft.value.length > 0 ? selectedHerkunft.value.includes(v.herkunft) : true))
          .filter((v: Variable) => v.titel.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()))
          .sort((a: Variable, b: Variable) => {
            if (sortByValue.value == 'titeldesc' || sortByValue.value == 'titelasc') {
              var titleComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase());
              if (sortByValue.value == 'titeldesc') {
                titleComparison = -1 * titleComparison;
              }

              return titleComparison;
            }

            if (sortByValue.value == 'platzhalterdesc' || sortByValue.value == 'platzhalterasc') {
              var platzhalterComparison = a.platzhalter.trim().toLowerCase().localeCompare(b.platzhalter.trim().toLowerCase());
              if (sortByValue.value == 'platzhalterdesc') {
                platzhalterComparison = -1 * platzhalterComparison;
              }

              return platzhalterComparison;
            }

            if (sortByValue.value == 'herkunftdesc' || sortByValue.value == 'herkunftasc') {
              var herkunftComparison = a.herkunft.trim().toLowerCase().localeCompare(b.herkunft.trim().toLowerCase());
              if (sortByValue.value == 'herkunftdesc') {
                herkunftComparison = -1 * herkunftComparison;
              }

              return herkunftComparison;
            }

            if (sortByValue.value == 'felddesc' || sortByValue.value == 'feldasc') {
              var feldComparison = a.feld.trim().toLowerCase().localeCompare(b.feld.trim().toLowerCase());
              if (sortByValue.value == 'felddesc') {
                feldComparison = -1 * feldComparison;
              }

              return feldComparison;
            }

            if (sortByValue.value == 'typedesc' || sortByValue.value == 'typeasc') {
              var typeComparison = a.type.trim().toLowerCase().localeCompare(b.type.trim().toLowerCase());
              if (sortByValue.value == 'typedesc') {
                typeComparison = -1 * typeComparison;
              }

              return typeComparison;
            }

            return 0;
          });
      });

      function loadVariablen() {
        store.dispatch(VariablenActionTypes.GetVariablen, undefined);
      }

      return {
        searchString,
        selectedHerkunft,
        herkunft,
        sortByValue,
        sortBy,

        variablen,
        sortedVariablen,

        loading,
        errorMsg,
        loadVariablen,
      };
    },

    mounted() {
      if (this.variablen.length == 0) {
        this.loadVariablen();
      }
    },
  });
</script>

<style scoped>
  .table-col:hover {
    background: rgb(216, 217, 222);
    cursor: pointer;
  }

  .table-sort-icon {
    font-size: 0.7em;
  }

  .select-sort:hover {
    cursor: pointer;
  }
</style>
