import { Textbaustein, Variable } from './VorlageModels';

export class Vertrag {
  id: number;
  titel: string;
  personaldatenGuid: string;

  vertragsBeginn: Date | null;
  vertragsEnde: Date | null;

  vorlageid: number | null;

  textabschnitte: Array<TextAbschnitt>;
  inputs: Array<Input>;
  vertragdokumente: Array<VertragDokument>;

  vertragspartei1: Vertragspartei;
  vertragspartei2: Vertragspartei;

  adate: Date;
  mdate: Date;
  ddate: Date | null;
  edate: Date | null;
  vdate: Date | null;
  archivBenutzer: string;

  constructor() {
    this.id = 0;
    this.titel = '';
    this.personaldatenGuid = '';

    this.vorlageid = null;

    this.textabschnitte = new Array<TextAbschnitt>();
    this.inputs = new Array<Input>();
    this.vertragdokumente = new Array<VertragDokument>();

    this.vertragsBeginn = new Date();
    this.vertragsEnde = null;

    this.vertragspartei1 = new Vertragspartei();
    this.vertragspartei2 = new Vertragspartei();

    this.adate = new Date();
    this.mdate = new Date();
    this.ddate = null;
    this.edate = null;
    this.vdate = null;
    this.archivBenutzer = '';
  }
}

export class Vertragspartei {
  id: number;
  name: string;
  vorname: string;
  unternehmen: string;
  strasse: string;
  plz: string;
  ort: string;
  land: string;

  constructor() {
    this.id = 0;
    this.name = '';
    this.vorname = '';
    this.unternehmen = '';
    this.strasse = '';
    this.plz = '';
    this.ort = '';
    this.land = '';
  }
}

export class Input {
  id: number;
  vertragid: number;
  variableid: number;
  variable: Variable;
  value: string;

  constructor(variableid: number, variable: Variable, vertragid: number, value: string) {
    this.id = 0;
    this.vertragid = vertragid;
    this.variableid = variableid;
    this.variable = variable;
    this.value = value;
  }
}

export class TextAbschnitt {
  id: number;
  position: number;
  selected: boolean;
  vertragid: number;
  textbaustein: Textbaustein;
  textbausteinid: number;
  ownerid: number | null;
  abschnitte: Array<TextAbschnitt>;

  constructor(
    id: number,
    vertragid: number,
    position: number,
    selected: boolean,
    textbausteinid: number,
    ownerid: number | null,
    abschnitte: Array<TextAbschnitt>
  ) {
    this.id = id;
    this.position = position;
    this.selected = selected;
    this.vertragid = vertragid;
    this.textbaustein = new Textbaustein();
    this.textbausteinid = textbausteinid;
    this.ownerid = ownerid;
    this.abschnitte = abschnitte;
  }
}

export class VertragDokument {
  id: number;
  vertragid: number;
  fileName: string;
  data: string;
  adate: Date;
  mdate: Date;
  archivBenutzer: string;

  constructor() {
    this.id = 0;
    this.vertragid = 0;
    this.fileName = '';
    this.data = '';
    this.adate = new Date();
    this.mdate = new Date();
    this.archivBenutzer = '';
  }
}

export class GenerateWordDocumentRequest {
  titel: string;
  text: string;
  dokumentId: number | null;
  fileName: string;

  constructor(titel: string, text: string, dokumentId: number | null = null, fileName: string = 'dokument.docx') {
    this.titel = titel;
    this.text = text;
    this.dokumentId = dokumentId;
    this.fileName = fileName;
  }
}
