import { Textbaustein, Vorlage } from "@/models/VorlageModels";


export type State = {
  vorlagen: Array<Vorlage>,
  textbausteine: Array<Textbaustein>
}

export const state: State = {
  vorlagen:  localStorage.getItem("vorlagen") != null && localStorage.getItem("vorlagen") !== undefined ? JSON.parse(localStorage.vorlagen) : new Array<Vorlage>(),
  textbausteine:  localStorage.getItem("textbausteine") != null && localStorage.getItem("textbausteine") !== undefined ? JSON.parse(localStorage.textbausteine) : new Array<Textbaustein>(),
}
