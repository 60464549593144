import { MutationTree } from "vuex";
import { State } from "./state";

import { Mandant, Personaldaten } from "@/models/PersonaldatenModels";

export enum MutationTypes {
  SetPersonaldaten = "SETPERSONALDATEN",
  AddOrUpdatePersonaldaten = "ADDORUPDATEPERSONALDATEN",
  SetMandanten = "SETMANDANTEN",
}

export type Mutations = {
  [MutationTypes.SetPersonaldaten](state: State, data: Array<Personaldaten>): void
  [MutationTypes.AddOrUpdatePersonaldaten](state: State, data: Array<Personaldaten>): void
  [MutationTypes.SetMandanten](state: State, data: Array<Mandant>): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetPersonaldaten](state, data) {
    state.personaldaten = data;
    localStorage.setItem("personaldaten", JSON.stringify(state.personaldaten));
  },

  [MutationTypes.AddOrUpdatePersonaldaten](state, data) {
    for (var pers of data) {
      var index = state.personaldaten.findIndex(p => p.id == pers.id)
      if (index != -1) {
        state.personaldaten.splice(index, 1, pers)
      }
      else {
        state.personaldaten.push(pers)
      }
    }

    localStorage.setItem("personaldaten", JSON.stringify(state.personaldaten));
  },
  
  [MutationTypes.SetMandanten](state, data) {
    state.mandanten = data;
    localStorage.setItem("mandanten", JSON.stringify(state.mandanten));
  },

}