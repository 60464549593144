<template>
  <div class="d-flex justify-content-between">
    <h1>Textbausteine</h1>

    <button class="btn" @click="loadTextbausteine()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="row mb-3">
    <div class="col-4">
      <router-link :to="{ name: 'EditTextbaustein' }" class="btn btn-primary"><font-awesome-icon icon="plus" /> Erstellen</router-link>
    </div>

    <div class="col-4">
      <div class="input-group input-group-sm my-auto">
        <input v-model.trim="searchString" type="search" class="form-control" id="searchInput" placeholder="Suche..." />
        <span class="input-group-text">
          <font-awesome-icon icon="magnifying-glass" class="p-1" />
        </span>
      </div>
    </div>
  </div>

  <LoadingSpinner v-if="loading" text="... Textbausteine werden geladen ..." />

  <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
    {{ errorMsg }}
    <!-- Es ist ein Fehler beim Laden der Vorlagen aufgetreten. Bite probieren Sie es später erneut. -->
  </div>

  <div v-if="textbausteine.length > 0" class="mt-2">
    <div class="row mx-1 mb-2 bg-white shadow-sm">
      <div class="col-3 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Baustein</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'nameasc'" class="text-secondary table-sort-icon" />
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'namedesc'" class="text-secondary table-sort-icon" />

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('nameasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'nameasc'" />
                </div>
                <div class="col">A - Z</div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('namedesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'namedesc'" />
                </div>
                <div class="col">Z - A</div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-6 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Überschrift</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'titelasc'" class="text-secondary table-sort-icon" />
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'titeldesc'" class="text-secondary table-sort-icon" />

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('titelasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'titelasc'" />
                </div>
                <div class="col">A - Z</div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('titeldesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'titeldesc'" />
                </div>
                <div class="col">Z - A</div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-1 my-auto"><strong>Abschnitte</strong></div>
      <div class="col-2"></div>
    </div>

    <TextbausteinRow v-for="textbaustein in sortedTextbausteine" :key="textbaustein.id" :textbaustein="textbaustein" />
  </div>

  <div v-else-if="!loading && errorMsg == '' && textbausteine.length == 0" class="alert alert-info mt-5">Es wurden keine Textbausteine gefunden.</div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref } from 'vue';
  import { useStore } from 'vuex';

  import { ActionTypes as VorlageActionTypes } from '@/store/modules/Vorlagen/actions';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import TextbausteinRow from '@/components/Vorlagen/TextbausteinRow.vue';
  import { Textbaustein } from '@/models/VorlageModels';

  export default defineComponent({
    name: 'Textbausteine',

    components: {
      LoadingSpinner,
      TextbausteinRow,
    },

    setup() {
      const store = useStore();

      const loading = computed(() => store.getters.status.getTextbausteineLoading);
      const errorMsg = computed(() => store.getters.status.textbausteinErrorMsg);

      const searchString = ref('');

      const sortByValue = ref('nameasc');
      function sortBy(param: string) {
        sortByValue.value = param;
      }
      const textbausteine = computed(() => store.getters.textbausteine);
      const sortedTextbausteine = computed(() => {
        return textbausteine.value
          .filter((textbaustein: Textbaustein) => {
            return (
              textbaustein.name.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
              textbaustein.titel.trim().toLowerCase().includes(searchString.value.trim().toLowerCase())
            );
          })
          .sort((a: Textbaustein, b: Textbaustein) => {
            if (sortByValue.value == 'nameasc' || sortByValue.value == 'namedesc') {
              var nameComparison = a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase());
              if (sortByValue.value == 'namedesc') {
                nameComparison = -1 * nameComparison;
              }

              if (nameComparison == 0) {
                var titelComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase());
                return titelComparison;
              }

              return nameComparison;
            }

            if (sortByValue.value == 'titelasc' || sortByValue.value == 'titeldesc') {
              var titleComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase());
              if (sortByValue.value == 'titeldesc') {
                titleComparison = -1 * titleComparison;
              }

              if (titleComparison == 0) {
                var nameComparison = a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase());
                return nameComparison;
              }

              return titleComparison;
            }

            return 0;
          });
      });

      function loadTextbausteine() {
        store.dispatch(VorlageActionTypes.GetTextbausteine, undefined);
      }

      return {
        searchString,
        sortByValue,
        sortBy,
        textbausteine,
        sortedTextbausteine,

        loading,
        errorMsg,
        loadTextbausteine,
      };
    },

    mounted() {
      if (this.textbausteine.length == 0) {
        this.loadTextbausteine();
      }
    },
  });
</script>

<style scoped>
  .table-col:hover {
    background: rgb(216, 217, 222);
    cursor: pointer;
  }

  .table-sort-icon {
    font-size: 0.7em;
  }

  .select-sort:hover {
    cursor: pointer;
  }
</style>
