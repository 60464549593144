<template>
  <div class="d-flex-col justify-content-center mt-5">
    <div class="mb-5 text-center">
      <!-- <font-awesome-icon icon="users-line" class="me-3 mb-2 fa-3x" /> -->
      <img
        src="@/assets/icons/personalakte.png"
        alt="Archiv Icon"
        width="64"
      />
      <h1>Vertragsmanager</h1>
    </div>


    <div class="text-center">
      <h2>Sie wurden erfolgreich abgemeldet</h2>
      <button class="btn btn-primary btn-lg mt-4" @click="toLogin()">Zurück zur Login Seite</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

function toLogin () {
  router.push({name: "Login"})
}

onBeforeMount(() => {
  if (localStorage.getItem('reloaded')) {
    localStorage.removeItem('reloaded');
  } else {
    localStorage.setItem('reloaded', '1');
    location.reload();
  }
})
</script>