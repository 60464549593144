<template>
  <div class="row mx-1 mb-2 p-1 bg-white shadow">
    <div class="col-3 my-auto">{{ variable.titel }}</div>
    <div class="col-3 my-auto">{{ variable.platzhalter }}</div>
    <div class="col-2 my-auto">{{ variable.herkunft }}</div>
    <div class="col-2 my-auto">{{ variable.feld }}</div>
    <div class="col-1 my-auto">{{ variable.type }}</div>
    <div class="col-1 my-auto d-flex justify-content-end">
      <EditVariableModal :index="variable.id" :buttonType="1" :variable="variable" @delete-variable="deleteVariable" />
    </div>
  </div>

</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { ActionTypes as VariablenActionTypes } from '@/store/modules/Variablen/actions';

import { Variable } from '@/models/VorlageModels';

import EditVariableModal from "@/components/Vorlagen/EditVariableModal.vue";

export default defineComponent({
  name: "VariableRow",

  components: {
    EditVariableModal
  },

  props: {
    variable: {
      type: Object as PropType<Variable>,
      required: true
    }
  },

  setup() {
    const store = useStore();

    function deleteVariable(id: number) {
      store.dispatch(VariablenActionTypes.DeleteVariable, id)
    }

    return {
      deleteVariable
    }
  }
})
</script>