<template>
  <div class="row mb-3">
    <div class="col fw-bold my-auto">Variablen</div>

    <div class="col my-auto">
      <div class="input-group input-group-sm my-auto">
        <input v-model.trim="searchString" type="search" class="form-control" id="searchInput" placeholder="Suche..." />
        <span class="input-group-text">
          <font-awesome-icon icon="magnifying-glass" class="p-1" />
        </span>
      </div>
    </div>

    <div class="col text-end my-auto">
      <div class="dropdown">
        <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="select-herkunft-button" data-bs-toggle="dropdown" aria-expanded="false">
          Herkunft auswählen...
        </button>
        <div class="dropdown-menu p-0" aria-labelledby="select-herkunft-button">
          <div
            v-for="(table, index) in herkunft.sort((a: string, b: string) => a.localeCompare(b))"
            :key="index"
            class="dropdown-checkbox px-1 py-2 border-bottom"
          >
            <div class="form-check">
              <input class="form-check-input" type="checkbox" :id="'selected-herkunft-item-' + index" v-model="selectedHerkunft" :value="table" />
              <label class="form-check-label" :for="'selected-herkunft-item-' + index">{{ table != '' ? table : 'Unbekannt' }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <LoadingSpinner v-if="getVariablenLoading" />

    <div v-else-if="variablenErrorMsg != ''" class="alert alert-danger">
      {{ variablenErrorMsg }}
    </div>

    <div class="d-flex flex-wrap mb-4 align-items-center">
      <span
        v-for="(variable, idx) in sortedVariablen"
        :key="variable.id"
        :id="'variable-' + idx"
        class="mb-1 me-1 border p-2 bg-white shadow-sm variable-content"
        @click="copyToClipboard(variable.platzhalter, idx)"
      >
        {{ variable.platzhalter }}
      </span>

      <span v-if="!getVariablenLoading" class="ms-2 mb-1">
        <EditVariableModal />
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { ref, computed } from 'vue';
  import { useStore } from '@/store';

  import EditVariableModal from '@/components/Vorlagen/EditVariableModal.vue';
  import LoadingSpinner from '@/components/LoadingSpinner.vue';

  import { Variable } from '@/models/VorlageModels';
  //#endregion

  const store = useStore();

  const getVariablenLoading = computed(() => store.getters.status.getVariablenLoading);
  const variablenErrorMsg = computed(() => store.getters.status.variablenErrorMsg);

  const variablen = computed(() => store.getters.variablen);

  const searchString = ref('');
  const selectedHerkunft = ref<Array<string>>([]);
  const herkunft = computed(() => {
    return variablen.value.map((variable: Variable) => variable.herkunft).filter((value: any, index: any, self: any) => self.indexOf(value) === index);
  });

  const sortedVariablen = computed(() => {
    return variablen.value
      .filter((v: Variable) => (selectedHerkunft.value.length > 0 ? selectedHerkunft.value.includes(v.herkunft) : true))
      .filter((v: Variable) => v.titel.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()))
      .sort((a: Variable, b: Variable) => a.platzhalter.trim().toLowerCase().localeCompare(b.platzhalter.trim().toLowerCase()));
  });

  function copyToClipboard(text: string, idx: number) {
    navigator.clipboard.writeText(text);

    const id = 'variable-' + idx;
    const currentElement = document.getElementById(id) as HTMLElement;

    // Save the original text
    const originalText = currentElement.textContent;

    // Add class and new text
    currentElement.classList.add('variable-content-copy', 'text-center');
    currentElement.textContent = 'Variable kopiert!';

    // Remove class and restore text after 4 seconds
    setTimeout(() => {
      currentElement.classList.remove('variable-content-copy');
      currentElement.classList.remove('text-center');
      currentElement.textContent = originalText;
    }, 2000); // 4000 milliseconds = 4 seconds
  }
</script>

<style scoped>
  .variable-content {
    cursor: pointer;
    user-select: none;
  }

  .variable-content:hover {
    background-color: #fff4af !important;
  }

  .variable-content-copy {
    background-color: #fff4af !important;
  }
</style>
