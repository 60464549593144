<template>
  <div class="row bg-white py-2 mx-1 mb-2 shadow">
    <div class="col my-auto d-flex justify-content-between">
      <strong>{{ dokument.fileName }}</strong>
      <span v-if="new Date(dokument.adate).getTime() !== new Date(dokument.mdate).getTime()" class="text-primary"><font-awesome-icon icon="pen-ruler" /></span>
    </div>
    <div class="col-2 my-auto">{{ dateToDateTimeConverter(dokument.adate) }}</div>
    <div class="col-2 my-auto">{{ dateToDateTimeConverter(dokument.mdate) }}</div>
    <div class="col-2 my-auto">
      <div class="btn-group float-end" role="group">
        <button type="button" class="btn btn-secondary" @click="showModal()"><font-awesome-icon icon="magnifying-glass-plus" /></button>
        <DownloadVertragDocumentModal :dokument="dokument" :titel="titel" />
        <!-- EditVertragDokument -->
        <router-link :to="{ name: 'EditVertragDokument', query: { vertragid: dokument.vertragid, dokumentid: dokument.id } }" class="btn btn-secondary"
          ><font-awesome-icon icon="pen"
        /></router-link>
        <!-- <button type="button" class="btn btn-secondary disabled" @click="showModal()"><font-awesome-icon icon="pen" /></button> -->
        <button type="button" class="btn btn-danger" @click="showDeleteModal()"><font-awesome-icon icon="trash" /></button>
      </div>
    </div>
  </div>

  <!-- PREVIEW MODAL -->
  <div class="modal fade" :id="'dokument-text-' + dokument.id" tabindex="-1" aria-labelledby="dokumentTextModal" aria-hidden="true" ref="dokumentTextModalRef">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary py-2">
          <strong>{{ dokument.fileName }}</strong>

          <button type="button" class="btn-close btn-sm text-white" @click="closeModal()"></button>
        </div>

        <div class="modal-body bg-white">
          <div class="content-text">
            <div v-html="previewText"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- DELETE MODAL -->
  <div
    class="modal fade"
    :id="'dokument-delete-' + dokument.id"
    tabindex="-1"
    aria-labelledby="dokumentDeleteModal"
    aria-hidden="true"
    ref="dokumentDeleteModalRef"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-danger text-white py-2">
          <strong>{{ dokument.fileName }} löschen</strong>

          <button type="button" class="btn-close btn-sm" @click="closeDeleteModal()"></button>
        </div>

        <div class="modal-body">Soll das Dokument <strong>unwiderruflich</strong> gelöscht werden?</div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeDeleteModal()">Abbrechen</button>
          <button type="button" class="btn btn-danger" @click="deleteVertragDokument()">Löschen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { PropType, defineComponent, ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import { Modal } from 'bootstrap';
  import { useDateConverter } from '@/utils/date_converter';

  import { ActionTypes as VertragActionTypes } from '@/store/modules/Vertraege/actions';

  import DownloadVertragDocumentModal from '@/components/Vertrag/DownloadVertragDocumentModal.vue';

  import { VertragDokument } from '@/models/VertragModels';

  export default defineComponent({
    name: 'VertragDokumentRow',

    components: {
      DownloadVertragDocumentModal,
    },

    props: {
      dokument: {
        type: Object as PropType<VertragDokument>,
        required: true,
      },
      titel: {
        type: String as PropType<string>,
        required: true,
      },
    },

    setup(props) {
      const store = useStore();

      const { dateToDateTimeConverter } = useDateConverter();

      const previewText = computed(() => {
        let text = `<h1>${props.titel}</h1>`;
        text += `${props.dokument.data}`;
        return text;
      });

      const dokumentTextModalRef = ref();
      function closeModal() {
        Modal.getInstance(dokumentTextModalRef.value)?.hide();
      }
      function showModal() {
        if (dokumentTextModalRef.value) {
          new Modal(dokumentTextModalRef.value).show();
        }
      }

      const dokumentDeleteModalRef = ref();
      function closeDeleteModal() {
        Modal.getInstance(dokumentDeleteModalRef.value)?.hide();
      }
      function showDeleteModal() {
        if (dokumentDeleteModalRef.value) {
          new Modal(dokumentDeleteModalRef.value).show();
        }
      }

      function deleteVertragDokument() {
        closeDeleteModal();
        store.dispatch(VertragActionTypes.DeleteVertragDokument, props.dokument);
      }

      return {
        dateToDateTimeConverter,

        previewText,

        dokumentTextModalRef,
        showModal,
        closeModal,

        dokumentDeleteModalRef,
        closeDeleteModal,
        showDeleteModal,

        deleteVertragDokument,
      };
    },
  });
</script>
