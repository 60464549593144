import {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import { AuthUser } from "@/models/AuthModels";

import { useStore } from '@/store'
import router from "@/router"
import { ActionTypes } from "@/store/modules/Auth/actions";
import { ActionTypes as AuthActionTypes } from "@/store/modules/Auth/actions";
import { ErrorResponseData } from "@/models/CommonModels";

let axiosInst: AxiosInstance;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  // console.info(`[request] [${JSON.stringify(config)}]`);

  if (config.headers === undefined) {
    config.headers = {};
  }

  const archivUser: AuthUser = localStorage.getItem("authUser") != null ? JSON.parse(localStorage.authUser) : null;

  if (archivUser != null && archivUser.token != null) {
    config.headers.Authorization = `Bearer ${archivUser.token}`;
  }

  return config;
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
}

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = [];
// Flag to prevent multiple token refresh requests
// let isRefreshing = false;
let isRefreshing = sessionStorage.getItem("isRefreshing") == "true" ? true : false;

const onResponseError = async (error: AxiosError): Promise<any> => {
  const originalRequest: AxiosRequestConfig = error.config;
  const store = useStore();

  if (error.response && error.response.status == 401) {
    
    if (!isRefreshing) {
      isRefreshing = true;
      sessionStorage.setItem("isRefreshing", "true");

      try {
        await store.dispatch(ActionTypes.RefreshLogin, undefined)
        const newAccessToken = store.getters.authUser?.token;

        // Update the request headers with the new access token
        if (error.config.headers) {
          error.config.headers['Authorization'] = `Bearer ${newAccessToken}`
        }

        // Retry all requests in the queue with the new token
        refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
          axiosInst
          .request(config)
          .then((response: AxiosResponse) => resolve(response))
          .catch((err: AxiosError) => reject(err));
        });

        // Clear the queue
        refreshAndRetryQueue.length = 0;

        // Retry the original request
        return axiosInst(originalRequest)

      } catch (refreshError) {
        // Handle token refresh error
        // You can clear all storage and redirect the user to the login page
        store.dispatch(ActionTypes.Logout, undefined)
        .then(() => {
          router.replace({name: "Logout"})
          return true
        })
      } finally {
        isRefreshing = false;
      sessionStorage.setItem("isRefreshing", "false");
      }
    }

    // Add the original request to the queue
    return new Promise<any>((resolve, reject) => {
      refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
    });
  }
  
  else if (error.response?.status == 403) {
    const errorRespData = error.response.data as ErrorResponseData || null;
    if (errorRespData != null && errorRespData.detail == "Die Anmeldung ist abgelaufen") {
      store.dispatch(AuthActionTypes.Logout, undefined)
      .then(() => {
        router.replace({ name: "Logout" })
        return true;
      })
    }
  }

  // console.error(`[response error] [${JSON.stringify(error)}]`);

  // if (error.response?.status == 401){
  //   useStore().dispatch(ActionTypes.Logout, undefined);
  //   location.reload();
  // }

  console.log(error);

  return Promise.reject(error);
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  axiosInst = axiosInstance
  return axiosInstance;
}
