import { Vertrag } from "@/models/VertragModels";


export type State = {
  vertraege: Array<Vertrag>
}

export const state: State = {
  vertraege:  localStorage.getItem("vertraege") != null && localStorage.getItem("vertraege") !== undefined ? JSON.parse(localStorage.vertraege) : new Array<Vertrag>(),
}
