import { MutationTree } from "vuex";
import { State } from "./state";

import { Variable } from "@/models/VorlageModels";

export enum MutationTypes {
  SetVariablen = "SETVARIABLEN",
  AddVariable = "ADDVARIABLE",
  AddOrUpdateVariable = "UPDATEVARIABLE",
  DeleteVariable = "DELETEVARIABLE",
}

export type Mutations = {
  [MutationTypes.SetVariablen](state: State, data: Array<Variable>): void
  [MutationTypes.AddVariable](state: State, data: Variable): void
  [MutationTypes.AddOrUpdateVariable](state: State, data: Variable): void
  [MutationTypes.DeleteVariable](state: State, data: number): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetVariablen](state, data) {
    state.variablen = data;
    localStorage.setItem("variablen", JSON.stringify(state.variablen));
  },
  
  [MutationTypes.AddVariable](state, data) {
    state.variablen.push(data)
    localStorage.setItem("variablen", JSON.stringify(state.variablen));
  },
  
  [MutationTypes.AddOrUpdateVariable](state, data) {
    var index = state.variablen.findIndex(p => p.id == data.id)
    if (index != -1) {
      state.variablen.splice(index, 1, data)
    }
    else {
      state.variablen.push(data)
    }
    
    localStorage.setItem("variablen", JSON.stringify(state.variablen));
  },
  
  [MutationTypes.DeleteVariable](state, data) {
    var index = state.variablen.findIndex(p => p.id == data)
    if (index != -1) {
      state.variablen.splice(index, 1)
    }
    
    localStorage.setItem("variablen", JSON.stringify(state.variablen));
  },

}