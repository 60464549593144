import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";
import { Textbaustein, Vorlage } from "@/models/VorlageModels";


export type Getters = {
  vorlagen(state: State): Array<Vorlage>,
  textbausteine(state: State): Array<Textbaustein>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  vorlagen(state) {
    return state.vorlagen;
  },
  
  textbausteine(state) {
    return state.textbausteine;
  },
}
