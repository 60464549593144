<template>
  <div class="d-flex justify-content-between">
    <h1>Vertragspartner</h1>

    <button class="btn" @click="loadVertragspartner()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="row mb-3">
    <div class="col-4">
      <router-link :to="{name: 'EditVertragspartner' }" class="btn btn-primary"><font-awesome-icon icon="plus" /> Erstellen</router-link>
    </div>

    <div class="col-4">
      <div class="input-group input-group-sm my-auto">
        <input v-model.trim="searchString" type="search" class="form-control" id="searchInput" placeholder="Suche...">
        <span class="input-group-text">
          <font-awesome-icon icon="magnifying-glass" class="p-1"/>
        </span>
      </div>
    </div>
  </div>

  <LoadingSpinner v-if="loading" text="... Vertragspartner werden geladen ..." />
  
  <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler aufgetreten:
    {{ errorMsg }}
  </div>

  <div v-if="vertragspartner.length > 0" class="mt-2">

    <div class="row mx-1 mb-2 bg-white shadow-sm">
      <div class="col-5 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Unternehmen</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'unternehmenasc'" class="text-secondary table-sort-icon"/>
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'unternehmendesc'" class="text-secondary table-sort-icon"/>

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('unternehmenasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'unternehmenasc'" />
                </div>
                <div class="col">
                  A - Z
                </div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('unternehmendesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'unternehmendesc'" />
                </div>
                <div class="col">
                  Z - A
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-3 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Name</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'nameasc'" class="text-secondary table-sort-icon"/>
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'namedesc'" class="text-secondary table-sort-icon"/>

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('nameasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'nameasc'" />
                </div>
                <div class="col">
                  A - Z
                </div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('namedesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'namedesc'" />
                </div>
                <div class="col">
                  Z - A
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-4 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Vorname</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'vornameasc'" class="text-secondary table-sort-icon"/>
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'vornamedesc'" class="text-secondary table-sort-icon"/>

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('vornameasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'vornameasc'" />
                </div>
                <div class="col">
                  A - Z
                </div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('vornamedesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'vornamedesc'" />
                </div>
                <div class="col">
                  Z - A
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <VertragspartnerRow v-for="partner in sortedVertragspartner" :key="partner.id" :vertragspartner="partner" />
  </div>

  <div v-else-if="!loading && errorMsg == '' && vertragspartner.length == 0" class="alert alert-info mt-5">
    Es wurden keine Vertragspartner gefunden.
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';

import { ActionTypes as VertragspartnerActionTypes } from '@/store/modules/Vertragspartner/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import VertragspartnerRow from '@/components/Vertragspartner/VertragspartnerRow.vue';

import { Vertragspartner } from '@/models/VertragspartnerModels';

export default defineComponent({
  name: "VertragspartnerView",

  components: {
    LoadingSpinner,
    VertragspartnerRow
  },

  setup() {
    const store = useStore()

    const loading = computed(() => store.getters.status.getVertragspartnerLoading)
    const errorMsg = computed(() => store.getters.status.vertragspartnerErrorMsg)

    
    const searchString = ref("")
    
    const sortByValue = ref("unternehmenasc")
    function sortBy (param: string) {
      sortByValue.value = param
    }
    
    const vertragspartner = computed(() => store.getters.vertragspartner)
    const sortedVertragspartner = computed(() => {
      return vertragspartner.value
      .filter((v: Vertragspartner) => {
        return v.unternehmen.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
            v.name.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
            v.vorname.trim().toLowerCase().includes(searchString.value.trim().toLowerCase())
      })
      .sort((a: Vertragspartner, b: Vertragspartner) => {
        if (sortByValue.value == "unternehmenasc" || sortByValue.value == "unternehmendesc") {
          var unternehmenComparison = a.unternehmen.trim().toLowerCase().localeCompare(b.unternehmen.trim().toLowerCase())
          if (sortByValue.value == "unternehmendesc") {
            unternehmenComparison = -1 * unternehmenComparison
          }
          
          if (unternehmenComparison == 0) {
            var nameComparison = a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase())
            return nameComparison
          }
          
          return unternehmenComparison;
        }

        else if (sortByValue.value == "nameasc" || sortByValue.value == "namedesc") {

          var nameComparison = a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase())

          if (sortByValue.value == "namedesc") {
            nameComparison = -1 * nameComparison
          }

          if (nameComparison == 0) {
            var vornameComparison = new Date(a.vorname).getTime() - new Date(b.vorname).getTime()
            return vornameComparison;
          }
          
          return nameComparison
        }

        else if (sortByValue.value == "vornameasc" || sortByValue.value == "vornamedesc") {
          var vornameComparison = a.vorname.trim().toLowerCase().localeCompare(b.vorname.trim().toLowerCase())
          if (sortByValue.value == "vornamedesc") {
            vornameComparison = -1 * vornameComparison
          }

          if (vornameComparison == 0) {
            var nameComparison = new Date(a.name).getTime() - new Date(b.name).getTime()
            return nameComparison;
          }
          
          return vornameComparison;
        }

        return 0;
      })
    })

    function loadVertragspartner() {
      store.dispatch(VertragspartnerActionTypes.GetVertragspartner, undefined)
    }

    return {
      loading,
      errorMsg,

      searchString,
      sortByValue,
      sortBy,
      vertragspartner,
      sortedVertragspartner,

      loadVertragspartner
    }
  },

  mounted () {
    this.loadVertragspartner();
  }
})
</script>

<style scoped>
.table-col:hover {
  background: rgb(216, 217, 222);
  cursor: pointer;
}

.table-sort-icon {
  font-size: .7em;
}

.select-sort:hover {
  cursor: pointer;
}
</style>