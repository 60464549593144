import { MutationTree } from "vuex";
import { State } from "./state";

import { Vertragspartner } from "@/models/VertragspartnerModels";

export enum MutationTypes {
  SetVertragspartner = "SETVERTRAGSPARTNER",
  AddVertragspartner = "ADDVERTRAGSPARTNER",
  AddOrUpdateVertragspartner = "UPDATEVERTRAGSPARTNER",
  DeleteVertragspartner = "DELETEVERTRAGSPARTNER",
}

export type Mutations = {
  [MutationTypes.SetVertragspartner](state: State, data: Array<Vertragspartner>): void
  [MutationTypes.AddVertragspartner](state: State, data: Vertragspartner): void
  [MutationTypes.AddOrUpdateVertragspartner](state: State, data: Vertragspartner): void
  [MutationTypes.DeleteVertragspartner](state: State, data: number): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetVertragspartner](state, data) {
    state.vertragspartner = data;
    localStorage.setItem("vertragspartner", JSON.stringify(state.vertragspartner));
  },
  
  [MutationTypes.AddVertragspartner](state, data) {
    state.vertragspartner.push(data)
    localStorage.setItem("vertragspartner", JSON.stringify(state.vertragspartner));
  },
  
  [MutationTypes.AddOrUpdateVertragspartner](state, data) {
    var index = state.vertragspartner.findIndex(p => p.id == data.id)
    if (index != -1) {
      state.vertragspartner.splice(index, 1, data)
    }
    else {
      state.vertragspartner.push(data)
    }
    
    localStorage.setItem("vertragspartner", JSON.stringify(state.vertragspartner));
  },
  
  [MutationTypes.DeleteVertragspartner](state, data) {
    var index = state.vertragspartner.findIndex(p => p.id == data)
    if (index != -1) {
      state.vertragspartner.splice(index, 1)
    }
    
    localStorage.setItem("vertragspartner", JSON.stringify(state.vertragspartner));
  },

}