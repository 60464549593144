import { MutationTree } from "vuex";
import { State } from "./state";

import { Druckvorlage } from "@/models/DruckvorlageModels";

export enum MutationTypes {
  SetDruckvorlagen = "SETDRUCKVORLAGEN",
  AddDruckvorlage = "ADDDRUCKVORLAGE",
  AddOrUpdateDruckvorlage = "UPDATEDRUCKVORLAGE",
  DeleteDruckvorlage = "DELETEDRUCKVORLAGE",
}

export type Mutations = {
  [MutationTypes.SetDruckvorlagen](state: State, data: Array<Druckvorlage>): void
  [MutationTypes.AddDruckvorlage](state: State, data: Druckvorlage): void
  [MutationTypes.AddOrUpdateDruckvorlage](state: State, data: Druckvorlage): void
  [MutationTypes.DeleteDruckvorlage](state: State, data: number): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetDruckvorlagen](state, data) {
    state.druckvorlagen = data;
    localStorage.setItem("druckvorlagen", JSON.stringify(state.druckvorlagen));
  },
  
  [MutationTypes.AddDruckvorlage](state, data) {
    state.druckvorlagen.push(data)
    localStorage.setItem("druckvorlagen", JSON.stringify(state.druckvorlagen));
  },
  
  [MutationTypes.AddOrUpdateDruckvorlage](state, data) {
    var index = state.druckvorlagen.findIndex(p => p.id == data.id)
    if (index != -1) {
      state.druckvorlagen.splice(index, 1, data)
    }
    else {
      state.druckvorlagen.push(data)
    }
    
    localStorage.setItem("druckvorlagen", JSON.stringify(state.druckvorlagen));
  },
  
  [MutationTypes.DeleteDruckvorlage](state, data) {
    var index = state.druckvorlagen.findIndex(p => p.id == data)
    if (index != -1) {
      state.druckvorlagen.splice(index, 1)
    }
    
    localStorage.setItem("druckvorlagen", JSON.stringify(state.druckvorlagen));
  },

}