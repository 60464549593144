

export function useDateConverter() {

  function dateToDateConverter(date: Date | string): string {
    if (typeof date === "string") {
      date = new Date(date);
    }

    return ("0" + date.getDate()).slice(-2) + "." +("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
  }

  function dateToDateTimeConverter(date: Date | string): string {
    if (typeof date === "string") {
      date = new Date(date);
    }

    return ("0" + date.getDate()).slice(-2) + "." +("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
  }

  function dateToDateInputConverter(date: Date | string): string {
    if (typeof date === "string") {
      date = new Date(date);
    }

    return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
  }

  function dateToReverseShortConverter(date: Date | string): string {
    if (typeof date === "string") {
      date = new Date(date);
    }

    return date.getFullYear() + ("0" + (date.getMonth() + 1)).slice(-2) + ("0" + date.getDate()).slice(-2);
  }

  return {
    dateToDateConverter,
    dateToDateTimeConverter,
    dateToDateInputConverter,
    dateToReverseShortConverter
  };  
}