import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
import ApiService from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { Vertragspartner } from '@/models/VertragspartnerModels';


export enum ActionTypes {
  GetVertragspartner = "GETVERTRAGSPARTNER",
  CreateVertragspartner = "CREATEVERTRAGSPARTNER",
  UpdateVertragspartner = "UPDATEVERTRAGSPARTNER",
  DeleteVertragspartner = "DELETEVERTRAGSPARTNER",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetVertragspartner](context: ActionArguments): Promise<void>
  [ActionTypes.CreateVertragspartner](context: ActionArguments, data: Vertragspartner): Promise<void>
  [ActionTypes.UpdateVertragspartner](context: ActionArguments, data: Vertragspartner): Promise<void>
  [ActionTypes.DeleteVertragspartner](context: ActionArguments, vertragspartnerid: number): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetVertragspartner]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getVertragspartnerLoading = true;
    status.vertragspartnernErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    commit(MutationTypes.SetVertragspartner, new Array<Vertragspartner>())

    return ApiService.getAllVertragspartner()
      .then(res => {
        const data: Array<Vertragspartner> = res.data
        commit(MutationTypes.SetVertragspartner, data)
      })
      .catch(error => {
        status.vertragspartnernErrorMsg = error.response.data.detail;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getVertragspartnerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateVertragspartner]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createVertragspartnerLoading = true;
    status.vertragspartnernErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createVertragspartner(data)
      .then(res => {
        const data: Vertragspartner = res.data
        commit(MutationTypes.AddVertragspartner, data)
      })
      .catch(error => {
        console.log(error);
        status.vertragspartnernErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createVertragspartnerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateVertragspartner]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateVertragspartnerLoading = true;
    status.vertragspartnernErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateVertragspartner(data)
      .then(res => {
        const data: Vertragspartner = res.data
        commit(MutationTypes.AddOrUpdateVertragspartner, data)
      })
      .catch(error => {
        status.vertragspartnernErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateVertragspartnerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteVertragspartner]({commit, dispatch, rootGetters}, vertragspartnerid) {
    const status = rootGetters.status;
    status.deleteVertragspartnerLoading = true;
    status.vertragspartnernErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteVertragspartner(vertragspartnerid)
      .then(res => {
        commit(MutationTypes.DeleteVertragspartner, vertragspartnerid)
      })
      .catch(error => {
        console.log(error);
        status.vertragspartnernErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteVertragspartnerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
}