export class Vorlage {
  id: number;
  name: string;
  titel: string;

  vorlageTextbausteine: Array<VorlageTextbaustein>;
  adate: Date;
  mdate: Date;
  archivBenutzer: string;

  constructor() {
    this.id = 0;
    this.name = '';
    this.titel = '';
    this.vorlageTextbausteine = new Array<VorlageTextbaustein>();
    this.adate = new Date();
    this.mdate = new Date();
    this.archivBenutzer = '';
  }
}

export class VorlageTextbaustein {
  id: number;
  vorlageid: number;
  textbausteinid: number;
  textbaustein: Textbaustein;
  position: number;
  optional: boolean;
  adate: Date;
  mdate: Date;
  archivBenutzer: string;

  constructor(vorlageid: number, textbaustein: Textbaustein, position: number) {
    this.id = 0;
    this.vorlageid = vorlageid;
    this.textbausteinid = textbaustein.id;
    this.textbaustein = textbaustein;
    this.position = position;
    this.optional = false;
    this.adate = new Date();
    this.mdate = new Date();
    this.archivBenutzer = '';
  }
}

export class Textbaustein {
  id: number;
  name: string;
  titel: string;
  text: string;
  infotext: string;
  position: number;
  ownerid: number | null;
  abschnitte: Array<Textbaustein>;
  adate: Date;
  mdate: Date;
  archivBenutzer: string;

  constructor() {
    this.id = 0;
    this.name = '';
    this.titel = '';
    this.text = '';
    this.infotext = '';
    this.position = 1;
    this.ownerid = null;
    this.abschnitte = new Array<Textbaustein>();
    this.adate = new Date();
    this.mdate = new Date();
    this.archivBenutzer = '';
  }
}

export class Variable {
  id: number;
  titel: string;
  platzhalter: string;
  herkunft: string;
  feld: string;
  type: string;
  values: Array<VariableValue>;

  minLength: number;
  maxLength: number;
  validationType: string | null; // "text", "int", "float", "currency"

  adate: Date;
  mdate: Date;
  archivBenutzer: string;

  constructor() {
    this.id = 0;
    this.titel = '';
    this.platzhalter = '';
    this.herkunft = '';
    this.feld = '';
    this.type = 'text';
    this.values = new Array<VariableValue>();

    this.minLength = 0;
    this.maxLength = 80;
    this.validationType = 'text';

    this.adate = new Date();
    this.mdate = new Date();
    this.archivBenutzer = '';
  }
}

export class VariableValue {
  id: number;
  variableid: number;
  value: string;

  constructor() {
    this.id = 0;
    this.variableid = 0;
    this.value = '';
  }
}
