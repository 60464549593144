import { Variable } from "@/models/VorlageModels";


export type State = {
  variablen: Array<Variable>
}

export const state: State = {
  variablen:  localStorage.getItem("variablen") != null && localStorage.getItem("variablen") !== undefined ? JSON.parse(localStorage.variablen) : new Array<Variable>(),
}
