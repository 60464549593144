<template>
  <LoadingSpinner v-if="loading" text="... Daten werden gespeichert ..." />

  <div v-if="editDokument == undefined" class="alert alert-danger">
    Ungültes Dokument
  </div>


  <div v-else>
    <div v-if="errorMsg != ''" class="alert alert-danger">
      Es ist ein Fehler aufgetreten: {{ errorMsg }}
    </div>

    <div class="d-flex justify-content-between align-items-center mb-2">
      <span><strong>{{ editDokument.fileName }}</strong> bearbeiten</span>

      <span>
        <button class="btn btn-secondary" @click="cancel()">Abbrechen</button>
        <button class="btn btn-primary ms-3" @click="updateVertragDokument()"><font-awesome-icon icon="floppy-disk" /> Speichern</button>
      </span>
    </div>

    <TextEditor v-model="editDokument.data" />
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { ActionTypes as VertragActionTypes } from "@/store/modules/Vertraege/actions";

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import TextEditor from '@/components/TextEditor/TextEditor.vue';

import { Vertrag, VertragDokument } from '@/models/VertragModels';

export default defineComponent({
  name: "EditVertragDokumentView",

  components: {
    LoadingSpinner,
    TextEditor
  },

  props: {
    vertragid: {
      type: String as PropType<string>,
      required: true
    },

    dokumentid: {
      type: String as PropType<string>,
      required: true
    }
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const loading = computed(() => store.getters.status.updateVertragDokumentLoading);
    const errorMsg = computed(() => store.getters.status.vertragDokumentErrorMsg);

    
    const vertrag = computed(() => store.getters.vertraege.find((v: Vertrag) => v.id === parseInt(props.vertragid)));
    const dokument = computed(() => vertrag.value?.vertragdokumente.find((d: VertragDokument) => d.id === parseInt(props.dokumentid)));

    const editDokument = ref<VertragDokument | undefined>(dokument.value ? JSON.parse(JSON.stringify(dokument.value)) : undefined);

    function sanitizeHTML(htmlString: string): string {
      // Regular expression to remove <script> tags and JavaScript code
      const sanitizedHTML = htmlString.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
      return sanitizedHTML;
    }

    function updateVertragDokument() {
      if (editDokument.value === undefined) {
        return;
      }

      const requestData = JSON.parse(JSON.stringify(editDokument.value));
      if (requestData.data === '') {
        requestData.data = sanitizeHTML(requestData.data);
      }

      store.dispatch(VertragActionTypes.UpdateVertragDokument, requestData)
      .then(() => {
        if (errorMsg.value === '') {
          cancel()
        }
      })
    }

    function cancel() {
      router.back()
    }

    return {
      loading,
      errorMsg,

      editDokument,
      updateVertragDokument,
      cancel
    }
  }
});
</script>