import { MutationTree } from "vuex";
import { State } from "./state";

import { Mandant, Personaldaten } from "@/models/PersonaldatenModels";
import { Textbaustein, Vorlage } from "@/models/VorlageModels";

export enum MutationTypes {
  SetVorlagen = "SETVORLAGEN",
  AddVorlage = "ADDVORLAGE",
  AddOrUpdateVorlage = "UPDATEVORLAGE",
  DeleteVorlage = "DELETEVORLAGE",

  SetTextbausteine = "SETTEXTBAUSTEINE",
  AddTextbaustein = "ADDTEXTBAUSTEIN",
  AddOrUpdateTextbaustein = "UPDATETEXTBAUSTEIN",
  DeleteTextbaustein = "DELETETEXTBAUSTEIN",
}

export type Mutations = {
  [MutationTypes.SetVorlagen](state: State, data: Array<Vorlage>): void
  [MutationTypes.AddVorlage](state: State, data: Vorlage): void
  [MutationTypes.AddOrUpdateVorlage](state: State, data: Vorlage): void
  [MutationTypes.DeleteVorlage](state: State, data: number): void

  [MutationTypes.SetTextbausteine](state: State, data: Array<Textbaustein>): void
  [MutationTypes.AddTextbaustein](state: State, data: Textbaustein): void
  [MutationTypes.AddOrUpdateTextbaustein](state: State, data: Textbaustein): void
  [MutationTypes.DeleteTextbaustein](state: State, data: number): void
}

export const mutations: MutationTree<State> & Mutations = {
  //#region Vorlagen
  [MutationTypes.SetVorlagen](state, data) {
    state.vorlagen = data;
    localStorage.setItem("vorlagen", JSON.stringify(state.vorlagen));
  },
  
  [MutationTypes.AddVorlage](state, data) {
    state.vorlagen.push(data)
    localStorage.setItem("vorlagen", JSON.stringify(state.vorlagen));
  },
  
  [MutationTypes.AddOrUpdateVorlage](state, data) {
    var index = state.vorlagen.findIndex(p => p.id == data.id)
    if (index != -1) {
      state.vorlagen.splice(index, 1, data)
    }
    else {
      state.vorlagen.push(data)
    }
    
    localStorage.setItem("vorlagen", JSON.stringify(state.vorlagen));
  },
  
  [MutationTypes.DeleteVorlage](state, data) {
    var index = state.vorlagen.findIndex(p => p.id == data)
    if (index != -1) {
      state.vorlagen.splice(index, 1)
    }
    
    localStorage.setItem("vorlagen", JSON.stringify(state.vorlagen));
  },
  //#endregion

  //#region Textbausteine
  [MutationTypes.SetTextbausteine](state, data) {
    state.textbausteine = data;
    localStorage.setItem("textbausteine", JSON.stringify(state.textbausteine));
  },
  
  [MutationTypes.AddTextbaustein](state, data) {
    state.textbausteine.push(data)
    localStorage.setItem("textbausteine", JSON.stringify(state.textbausteine));
  },
  
  [MutationTypes.AddOrUpdateTextbaustein](state, data) {
    var index = state.textbausteine.findIndex(p => p.id == data.id)
    if (index != -1) {
      state.textbausteine.splice(index, 1, data)
    }
    else {
      state.textbausteine.push(data)
    }
    
    localStorage.setItem("textbausteine", JSON.stringify(state.textbausteine));
  },
  
  [MutationTypes.DeleteTextbaustein](state, data) {
    var index = state.textbausteine.findIndex(p => p.id == data)
    if (index != -1) {
      state.textbausteine.splice(index, 1)
    }
    
    localStorage.setItem("textbausteine", JSON.stringify(state.textbausteine));
  },
  //#endregion

}