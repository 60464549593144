import { RootState } from '@/store';
import { ActionContext, ActionTree } from 'vuex';
import { Mutations, MutationTypes } from './mutations';
import { State } from './state';
import ApiService from '@/services/ApiService';

import { ActionTypes as CommonActionTypes } from '../Common/actions';

import { Vertrag, TextAbschnitt, GenerateWordDocumentRequest, VertragDokument } from '@/models/VertragModels';

export enum ActionTypes {
  GetVertraege = 'GETVERTRAEGE',
  GetVertraegePersonaldaten = 'GETVERTRAEGEPERSONALDATEN',
  CreateVertrag = 'CREATEVERTRAG',
  UpdateVertrag = 'UPDATEVERTRAG',
  DeleteVertrag = 'DELETEVERTRAG',

  GenerateWordDocument = 'GENERATEWORDDOCUMENT',

  UpdateAbschnitt = 'UPDATEABSCHNITT',

  GetVertragVertragDokumente = 'GETVERTRAGVERTRAGDOKUMENTE',
  CreateVertragDokument = 'CREATEVERTRAGDOKUMENT',
  UpdateVertragDokument = 'UPDATEVERTRAGDOKUMENT',
  DeleteVertragDokument = 'DELETEVERTRAGDOKUMENT',
}

type ActionArguments = Omit<ActionContext<State, RootState>, 'commit'> & {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

export type Actions = {
  [ActionTypes.GetVertraege](context: ActionArguments): Promise<void>;
  [ActionTypes.GetVertraegePersonaldaten](context: ActionArguments, guid: string): Promise<void>;
  [ActionTypes.CreateVertrag](context: ActionArguments, data: Vertrag): Promise<void>;
  [ActionTypes.UpdateVertrag](context: ActionArguments, data: Vertrag): Promise<void>;
  [ActionTypes.DeleteVertrag](context: ActionArguments, vertragid: number): Promise<void>;

  [ActionTypes.GenerateWordDocument](context: ActionArguments, data: GenerateWordDocumentRequest): Promise<void>;

  [ActionTypes.UpdateAbschnitt](context: ActionArguments, data: TextAbschnitt): Promise<void>;

  [ActionTypes.GetVertragVertragDokumente](context: ActionArguments, vertragid: number): Promise<void>;
  [ActionTypes.CreateVertragDokument](context: ActionArguments, data: VertragDokument): Promise<void>;
  [ActionTypes.UpdateVertragDokument](context: ActionArguments, data: VertragDokument): Promise<void>;
  [ActionTypes.DeleteVertragDokument](context: ActionArguments, data: VertragDokument): Promise<void>;
};

export const actions: ActionTree<State, RootState> & Actions = {
  //#region Vertrag
  async [ActionTypes.GetVertraege]({ commit, dispatch, rootGetters }: ActionArguments) {
    const status = rootGetters.status;
    status.getVertraegeLoading = true;
    status.vertragErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    commit(MutationTypes.SetVertraege, new Array<Vertrag>());

    return ApiService.getAllVertraege()
      .then((res) => {
        const data: Array<Vertrag> = res.data;
        commit(MutationTypes.SetVertraege, data);
      })
      .catch((error) => {
        if (error.response.status == 404) {
          status.vertragErrorMsg = error.response.data.detail;
        } else {
          status.vertragErrorMsg = 'Es ist ein Fehler aufgetreten';
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getVertraegeLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.GetVertraegePersonaldaten]({ commit, dispatch, rootGetters }: ActionArguments, guid: string) {
    const status = rootGetters.status;
    status.getVertraegeLoading = true;
    status.vertragErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    commit(MutationTypes.SetVertraege, new Array<Vertrag>());

    return ApiService.getVertragByPersonaldaten(guid)
      .then((res) => {
        const data: Array<Vertrag> = res.data;
        commit(MutationTypes.SetVertraege, data);
      })
      .catch((error) => {
        if (error.response.status == 404) {
          status.vertragErrorMsg = error.response.data.detail;
        } else {
          status.vertragErrorMsg = 'Es ist ein Fehler aufgetreten';
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getVertraegeLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.CreateVertrag]({ commit, dispatch, rootGetters }: ActionArguments, data: Vertrag) {
    const status = rootGetters.status;
    status.createVertragLoading = true;
    status.vertragErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createVertrag(data)
      .then((res) => {
        const data: Vertrag = res.data;
        commit(MutationTypes.AddVertrag, data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 404) {
          status.vertragErrorMsg = error.response.data.detail;
        } else {
          status.vertragErrorMsg = 'Es ist ein Fehler aufgetreten';
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createVertragLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.UpdateVertrag]({ commit, dispatch, rootGetters }: ActionArguments, data: Vertrag) {
    const status = rootGetters.status;
    status.updateVertragLoading = true;
    status.vertragErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateVertrag(data)
      .then((res) => {
        const data: Vertrag = res.data;
        commit(MutationTypes.AddOrUpdateVertrag, data);
      })
      .catch((error) => {
        if (error.response.status == 404) {
          status.vertragErrorMsg = error.response.data.detail;
        } else {
          status.vertragErrorMsg = 'Es ist ein Fehler aufgetreten';
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateVertragLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.DeleteVertrag]({ commit, dispatch, rootGetters }: ActionArguments, vertragid: number) {
    const status = rootGetters.status;
    status.deleteVertragLoading = true;
    status.vertragErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteVertrag(vertragid)
      .then((res) => {
        commit(MutationTypes.DeleteVertrag, vertragid);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 404) {
          status.vertragErrorMsg = error.response.data.detail;
        } else {
          status.vertragErrorMsg = 'Es ist ein Fehler aufgetreten';
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteVertragLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },
  //#endregion

  async [ActionTypes.GenerateWordDocument]({ commit, dispatch, rootGetters }: ActionArguments, data: GenerateWordDocumentRequest) {
    const status = rootGetters.status;
    status.createVertragLoading = true;
    status.vertragErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.generateWordDocument(data)
      .then((res) => {
        const fileName = res.data;

        return ApiService.getWordDocument(fileName).then((resp) => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', data.fileName);
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 404) {
          status.vertragErrorMsg = error.response.data.detail;
        } else {
          status.vertragErrorMsg = 'Es ist ein Fehler aufgetreten';
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createVertragLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.UpdateAbschnitt]({ commit, dispatch, rootGetters }: ActionArguments, data: TextAbschnitt) {
    const status = rootGetters.status;
    status.updateAbschnittLoading = true;
    status.abschnittErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateTextabschnitt(data)
      .then((res) => {
        const data: TextAbschnitt = res.data;
        const vertrag = rootGetters.vertraege.find((v: Vertrag) => v.id == data.vertragid);
        const abschnittIndex = vertrag.textabschnitte.findIndex((t: TextAbschnitt) => t.id == data.id);

        vertrag.textabschnitte.splice(abschnittIndex, 1, data);
        commit(MutationTypes.AddOrUpdateVertrag, vertrag);
      })
      .catch((error) => {
        status.abschnittErrorMsg = 'Es ist ein Fehler aufgetreten';
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateAbschnittLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  //#region VertragDokument
  async [ActionTypes.GetVertragVertragDokumente]({ commit, dispatch, rootGetters }: ActionArguments, vertragid: number) {
    const status = rootGetters.status;
    status.getVertragDokumenteLoading = true;
    status.vertragDokumentErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllVertragVertragDokumente(vertragid)
      .then((res) => {
        const data: Array<VertragDokument> = res.data;
        commit(MutationTypes.SetVertragDokumente, data);
      })
      .catch((error) => {
        if (error.response.status == 404) {
          status.vertragDokumentErrorMsg = error.response.data.detail;
        } else {
          status.vertragDokumentErrorMsg = 'Es ist ein Fehler aufgetreten';
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getVertragDokumenteLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.CreateVertragDokument]({ commit, dispatch, rootGetters }: ActionArguments, data: VertragDokument) {
    const status = rootGetters.status;
    status.createVertragDokumentLoading = true;
    status.vertragDokumentErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createVertragDokument(data)
      .then((res) => {
        const data: VertragDokument = res.data;
        commit(MutationTypes.AddOrUpdateVertragDokument, data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 404) {
          status.vertragDokumentErrorMsg = error.response.data.detail;
        } else {
          status.vertragDokumentErrorMsg = 'Es ist ein Fehler aufgetreten';
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createVertragDokumentLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.UpdateVertragDokument]({ commit, dispatch, rootGetters }: ActionArguments, data: VertragDokument) {
    const status = rootGetters.status;
    status.updateVertragDokumentLoading = true;
    status.vertragDokumentErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateVertragDokument(data)
      .then((res) => {
        const data: VertragDokument = res.data;
        commit(MutationTypes.AddOrUpdateVertragDokument, data);
      })
      .catch((error) => {
        if (error.response.status == 404) {
          status.vertragDokumentErrorMsg = error.response.data.detail;
        } else {
          status.vertragDokumentErrorMsg = 'Es ist ein Fehler aufgetreten';
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateVertragDokumentLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.DeleteVertragDokument]({ commit, dispatch, rootGetters }: ActionArguments, data: VertragDokument) {
    const status = rootGetters.status;
    status.deleteVertragDokumentLoading = true;
    status.vertragDokumentErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteVertragDokument(data.id)
      .then(() => {
        commit(MutationTypes.DeleteVertragDokument, data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 404) {
          status.vertragDokumentErrorMsg = error.response.data.detail;
        } else {
          status.vertragDokumentErrorMsg = 'Es ist ein Fehler aufgetreten';
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteVertragDokumentLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },
  //#endregion
};
