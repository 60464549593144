<template>
  <h1>Einstellungen</h1>

  <section class="bg-white shadow p-3 mt-4">
    <h2>Datenbank</h2>
    
    <LoadingSpinner v-if="loading" />
    
    <div v-else-if="dbUpToDate" class="alert alert-success">
      <strong class="text-success">
        <font-awesome-icon icon="circle-check" class="me-2" /> Die Datenbank ist aktuell
      </strong>
    </div>
    
    <div v-else class="alert alert-danger">
      <p class="text-danger fw-bold">
        <font-awesome-icon icon="circle-info" class="me-2" /> Ein Datenbank Update ist verfügbar
      </p>
      
      <div class="btn btn-secondary ms-3" @click="updateDB()"><font-awesome-icon icon="rotate" class="me-2" /> Datenbank Update ausführen</div>
    </div>
  </section>

</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import ApiService from "@/services/ApiService";
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default defineComponent({
  name: "Einstellungen",

  components: {
    LoadingSpinner
  },

  setup() {
    const loading = ref(false);
    const dbUpToDate = ref(true);

    async function isDBUpToDate() {
      loading.value = true;
      var response = await ApiService.isDatabaseUpToDate();
      
      if (response.status === 200) {
        dbUpToDate.value = response.data;
      }
      
      loading.value = false;
      console.log(response);
    }

    async function updateDB() {
      loading.value = true;
      var response = await ApiService.updateDatabase();
      if (response.status === 200) {
        dbUpToDate.value = response.data;
      }
      loading.value = false;
      console.log(response);
    }

    return {
      loading,
      dbUpToDate,

      isDBUpToDate,
      updateDB
    }
  },

  mounted() {
    this.isDBUpToDate();
  }
})
</script>