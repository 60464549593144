<template>
  <!-- {{ vertrag }} -->
  <button class="btn btn-outline-secondary" @click="goBack()"><font-awesome-icon icon="chevron-left" /> Zurück</button>

  <LoadingSpinner v-if="loading" text="... Daten werden geladen ..." />

  <div v-if="vertrag == null || vertrag.id == 0" class="alert alert-danger mt-5">Ungültige Vorlage ID: {{ vertragid }}</div>

  <div v-else>
    <!-- TITEL -->
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h1>{{ vertrag.titel }}</h1>

      <router-link :to="{ name: 'EditVertrag', query: { vertragid: vertragid } }" class="btn"><font-awesome-icon icon="pen" /></router-link>
    </div>

    <div v-if="vertragErrorMsg != ''" class="alert alert-danger">
      {{ vertragErrorMsg }}
    </div>

    <!-- BESCHREIBUNG -->
    <div class="bg-white shadow mb-4 p-2">
      <h3>Vertragslaufzeit</h3>
      <div class="row">
        <div class="col-6">
          <div class="row mb-2">
            <div class="col-4 fw-bold">Vertragsbeginn</div>
            <div v-if="vertrag.vertragsBeginn != null" class="col">
              {{ dateToDateConverter(vertrag.vertragsBeginn) }}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 fw-bold">Vertragsende</div>
            <div v-if="vertrag.vertragsEnde != null" class="col">
              {{ dateToDateConverter(vertrag.vertragsEnde) }}
            </div>
          </div>

          <div class="row">
            <div class="col-4 fw-bold">Personal GUID</div>
            <div class="col">{{ vertrag.personaldatenGuid }}</div>
          </div>
        </div>
      </div>

      <hr class="my-4" />

      <h3>Vertragspartner</h3>
      <div class="row">
        <div class="col">
          <h4>Vertragspartner 1</h4>
          <div class="row mb-2">
            <div class="col-4 fw-bold">Unternehmen</div>
            <div class="col">
              {{ vertrag.vertragspartei1?.unternehmen }}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 fw-bold">Name</div>
            <div class="col">{{ vertrag.vertragspartei1?.vorname }} {{ vertrag.vertragspartei1?.name }}</div>
          </div>

          <div class="row mb-2">
            <div class="col-4 fw-bold">Straße</div>
            <div class="col">
              {{ vertrag.vertragspartei1?.strasse }}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 fw-bold">PLZ / Ort</div>
            <div class="col">{{ vertrag.vertragspartei1?.plz }} {{ vertrag.vertragspartei1?.ort }}</div>
          </div>

          <div class="row mb-2">
            <div class="col-4 fw-bold">Land</div>
            <div class="col">
              {{ vertrag.vertragspartei1?.land }}
            </div>
          </div>
        </div>

        <div class="col">
          <h4>Vertragspartner 2</h4>
          <div class="row mb-2">
            <div class="col-4 fw-bold">Unternehmen</div>
            <div class="col">
              {{ vertrag.vertragspartei2?.unternehmen }}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 fw-bold">Name</div>
            <div class="col">{{ vertrag.vertragspartei2?.vorname }} {{ vertrag.vertragspartei2?.name }}</div>
          </div>

          <div class="row mb-2">
            <div class="col-4 fw-bold">Straße</div>
            <div class="col">
              {{ vertrag.vertragspartei2?.strasse }}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 fw-bold">PLZ / Ort</div>
            <div class="col">{{ vertrag.vertragspartei2?.plz }} {{ vertrag.vertragspartei2?.ort }}</div>
          </div>

          <div class="row mb-2">
            <div class="col-4 fw-bold">Land</div>
            <div class="col">
              {{ vertrag.vertragspartei2?.land }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-4" />

    <!-- TEXTABSCHNITTE -->
    <section>
      <div class="d-flex justify-content-between align-items-center">
        <h3>Abschnitte</h3>
        <!-- <button class="btn btn-primary" @click="generateDocument()"><font-awesome-icon icon="file-download" /> Dokument erstellen</button> -->
        <!-- <DownloadVertragDocumentModal :vertrag="vertrag" :personaldaten="personaldaten" /> -->
        <button type="button" class="btn btn-primary" @click="toggleAbschnitteCollapse()">
          <font-awesome-icon v-if="abschnitteIsCollapsed" icon="magnifying-glass-plus" />
          <font-awesome-icon v-else icon="close" />
        </button>
      </div>

      <div class="" :class="{ collapse: abschnitteIsCollapsed }">
        <div v-if="vertrag.textabschnitte.length > 0" class="mt-3">
          <AbschnittRow
            v-for="abschnitt in vertrag.textabschnitte.filter((a) => a.selected).sort((a: TextAbschnitt, b: TextAbschnitt) => a.position - b.position)"
            :key="abschnitt.id"
            :abschnitt="abschnitt"
            :inputs="vertrag.inputs"
          />
        </div>
      </div>
    </section>

    <hr class="my-4" />

    <section>
      <div class="d-flex justify-content-between align-items-center">
        <h3>Dokumente</h3>
        <!-- <button class="btn btn-primary" @click="generateDocument()"><font-awesome-icon icon="file-download" /> Dokument erstellen</button> -->
        <!-- <DownloadVertragDocumentModal :vertrag="vertrag" :personaldaten="personaldaten" /> -->
        <button type="button" class="btn btn-primary" @click="toggleDokumenteCollapse()">
          <font-awesome-icon v-if="dokumenteIsCollapsed" icon="magnifying-glass-plus" />
          <font-awesome-icon v-else icon="close" />
        </button>
      </div>

      <div :class="{ collapse: dokumenteIsCollapsed }">
        <LoadingSpinner v-if="dokumenteLoading" text="... Dokumente werden geladen ..." />

        <div v-else class="d-flex">
          <button class="btn btn-primary" @click="createNewVertragVertragDokument()"><font-awesome-icon icon="plus" /> Dokument erstellen</button>
        </div>

        <div v-if="!dokumenteLoading && vertragDokumentErrorMsg != ''" class="alert alert-danger my-2">
          {{ vertragDokumentErrorMsg }}
        </div>

        <div
          v-if="!dokumenteLoading && vertragDokumentErrorMsg == '' && (vertrag.vertragdokumente == null || vertrag.vertragdokumente.length == 0)"
          class="alert alert-info my-2"
        >
          Keine Dokumente vorhanden
        </div>

        <div v-if="!dokumenteLoading && vertrag.vertragdokumente != null && vertrag.vertragdokumente.length > 0" class="mt-3">
          <div class="row mx-1 mb-1">
            <div class="col fw-bold">Titel</div>
            <div class="col-2 fw-bold">Erstellt am</div>
            <div class="col-2 fw-bold">Bearbeitet am</div>
            <div class="col-2 fw-bold"></div>
          </div>

          <VertragDokumentRow
            v-for="dokument in vertrag.vertragdokumente.sort(
              (a: VertragDokument, b: VertragDokument) => new Date(b.mdate).getTime() - new Date(a.mdate).getTime()
            )"
            :key="dokument.id"
            :dokument="dokument"
            :titel="vertrag.titel"
          />
        </div>
      </div>
    </section>

    <hr class="my-4" />

    <button class="btn btn-danger" @click="deleteVertrag()"><font-awesome-icon icon="trash" /> Vertrag löschen</button>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, ref, PropType, onMounted } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';
  import { useDateConverter } from '@/utils/date_converter';

  import { ActionTypes as VertragActionTypes } from '@/store/modules/Vertraege/actions';
  import { ActionTypes as VariablenActionTypes } from '@/store/modules/Variablen/actions';
  import { ActionTypes as DokumenteActionTypes } from '@/store/modules/Druckvorlagen/actions';
  import { ActionTypes as VorlagenActionTypes } from '@/store/modules/Vorlagen/actions';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import AbschnittRow from '@/components/Vertrag/AbschnittRow.vue';
  import VertragDokumentRow from '@/components/Vertrag/VertragDokumentRow.vue';

  import { Vertrag, TextAbschnitt, VertragDokument } from '@/models/VertragModels';
  //#endregion

  const props = defineProps({
    vertragid: {
      type: String as PropType<string>,
      required: true,
    },

    guid: {
      type: String as PropType<string>,
      default: '',
    },
  });

  const store = useStore();
  const router = useRouter();

  const loading = computed(() => store.getters.status.createVertragLoading || store.getters.status.deleteVertragLoading);
  const vertragErrorMsg = computed(() => store.getters.status.vertragErrorMsg);

  const dokumenteLoading = computed(() => store.getters.status.getVertragDokumenteLoading);
  const vertragDokumentErrorMsg = computed(() => store.getters.status.vertragDokumentErrorMsg);

  const { dateToDateConverter, dateToReverseShortConverter } = useDateConverter();

  const vertrag = computed(() => {
    const data = store.getters.vertraege.find((v: Vertrag) => v.id == parseInt(props.vertragid));
    return data ? data : new Vertrag();
  });

  const abschnitteIsCollapsed = ref(true);
  function toggleAbschnitteCollapse() {
    abschnitteIsCollapsed.value = !abschnitteIsCollapsed.value;
  }

  const dokumenteIsCollapsed = ref(false);
  function toggleDokumenteCollapse() {
    dokumenteIsCollapsed.value = !dokumenteIsCollapsed.value;
  }

  function deleteVertrag() {
    store.dispatch(VertragActionTypes.DeleteVertrag, parseInt(props.vertragid)).then(() => {
      if (vertragErrorMsg.value == '') {
        goBack();
      }
    });
  }

  function goBack() {
    if (props.guid != '') {
      router.push({ name: 'DetailsPersonaldaten', params: { guid: props.guid } });
    } else {
      router.push({ name: 'Vertraege' });
    }
  }

  function replaceVariablesInText(text: string): string {
    for (let input of vertrag.value.inputs) {
      if (input.value != null && input.value != '') {
        let textKey = input.variable.platzhalter.replace('<<', '&lt;&lt;');
        textKey = textKey.replace('>>', '&gt;&gt;');

        if (input.variable.type.toLowerCase() == 'date') {
          text = text.replaceAll(textKey, dateToDateConverter(input.value));
        } else {
          text = text.replaceAll(textKey, input.value);
        }
      }
    }

    const variableRegex = /&lt;&lt;([^&]+)&gt;&gt;/g;

    let match;
    while ((match = variableRegex.exec(text)) !== null) {
      const span = document.createElement('span');
      span.style.backgroundColor = 'yellow';
      span.textContent = `${match[1]}`;

      text = text.replace(match[0], span.outerHTML);
    }

    return text;
  }

  function addSubabschnittWithHTMLListCheck(abschnitt: string, subabschnitt: string): string {
    // check if htmlContentString ends with </ol> or </ul> and section begins with <ol> or <ul>
    var endsWithOl = abschnitt.endsWith('</ol>');
    var beginsWithOl = subabschnitt.endsWith('</ol>');
    var endsWithUl = abschnitt.endsWith('</ul>');
    var beginsWithUl = subabschnitt.endsWith('</ul>');

    if ((endsWithOl && beginsWithOl) || (endsWithUl && beginsWithUl)) {
      // remove last </ol> from htmlContentString
      abschnitt = abschnitt.substring(0, abschnitt.length - 5);

      // add subAbschnitt.textbaustein.text without <ol> at the beginning
      abschnitt += subabschnitt.substring(4);
    } else {
      abschnitt += subabschnitt;
    }

    return abschnitt;
  }

  function generateDocumentContent(): string {
    let htmlContentString = '';

    for (var abschnitt of vertrag.value.textabschnitte.filter((a) => a.selected).sort((a: TextAbschnitt, b: TextAbschnitt) => a.position - b.position)) {
      if (abschnitt.textbaustein.titel.length > 0) {
        htmlContentString += '<h1>' + abschnitt.textbaustein.titel + '</h1>';
        console.log(abschnitt.textbaustein.titel);
      }

      htmlContentString += abschnitt.textbaustein.text;

      if (abschnitt.abschnitte != null && abschnitt.abschnitte.length > 0) {
        for (var subAbschnitt of abschnitt.abschnitte.filter((a) => a.selected).sort((a: TextAbschnitt, b: TextAbschnitt) => a.position - b.position)) {
          if (subAbschnitt.textbaustein.titel.length > 0) {
            htmlContentString += '<h2>' + subAbschnitt.textbaustein.titel + '</h2>';
            console.log(subAbschnitt.textbaustein.titel);
          }

          htmlContentString = addSubabschnittWithHTMLListCheck(htmlContentString, subAbschnitt.textbaustein.text);

          if (subAbschnitt.abschnitte != null && subAbschnitt.abschnitte.length > 0) {
            for (var subSubAbschnitt of subAbschnitt.abschnitte
              .filter((a) => a.selected)
              .sort((a: TextAbschnitt, b: TextAbschnitt) => a.position - b.position)) {
              if (subSubAbschnitt.textbaustein.titel.length > 0) {
                htmlContentString += '<h3>' + subSubAbschnitt.textbaustein.titel + '</h3>';
                console.log(subSubAbschnitt.textbaustein.titel);
              }

              htmlContentString = addSubabschnittWithHTMLListCheck(htmlContentString, subSubAbschnitt.textbaustein.text);
            }
          }
        }
      }
    }

    return replaceVariablesInText(htmlContentString);
  }

  function createNewVertragVertragDokument() {
    const data = new VertragDokument();
    data.vertragid = vertrag.value.id;
    data.fileName =
      vertrag.value.titel +
      '_' +
      vertrag.value.vertragspartei2?.name +
      '_' +
      vertrag.value.vertragspartei2?.vorname +
      '_' +
      dateToReverseShortConverter(new Date());
    data.data = generateDocumentContent();

    store.dispatch(VertragActionTypes.CreateVertragDokument, data);
  }

  onMounted(() => {
    if (store.getters.variablen.length == 0) {
      store.dispatch(VariablenActionTypes.GetVariablen, undefined);
    }

    if (store.getters.vorlagen.length == 0) {
      store.dispatch(VorlagenActionTypes.GetVorlagen, undefined);
    }

    store.dispatch(VertragActionTypes.GetVertragVertragDokumente, parseInt(props.vertragid));
    store.dispatch(DokumenteActionTypes.GetDruckvorlagen, undefined);
  });
</script>
