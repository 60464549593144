<template>
  <div class="d-flex justify-content-between">
    <h1>Verträge</h1>

    <button class="btn" @click="loadVertraege()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="row mb-3">
    <div class="col-4">
      <router-link :to="{ name: 'EditVertrag' }" class="btn btn-primary"><font-awesome-icon icon="plus" /> Erstellen</router-link>
    </div>

    <div class="col-4">
      <div class="input-group input-group-sm my-auto">
        <input v-model.trim="searchString" type="search" class="form-control" id="searchInput" placeholder="Suche..." />
        <span class="input-group-text">
          <font-awesome-icon icon="magnifying-glass" class="p-1" />
        </span>
      </div>
    </div>
  </div>

  <LoadingSpinner v-if="loading" text="... Verträge werden geladen ..." />

  <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
    {{ errorMsg }}
    <!-- Es ist ein Fehler beim Laden der Vorlagen aufgetreten. Bite probieren Sie es später erneut. -->
  </div>

  <div v-if="vertraege.length > 0" class="mt-2">
    <div class="row mx-1 mb-2 bg-white shadow-sm">
      <div class="col-3 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Vertrag</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'titelasc'" class="text-secondary table-sort-icon" />
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'titeldesc'" class="text-secondary table-sort-icon" />

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('titelasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'titelasc'" />
                </div>
                <div class="col">A - Z</div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('titeldesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'titeldesc'" />
                </div>
                <div class="col">Z - A</div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-3 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Vertragspartner 1</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'vertragspartner1asc'" class="text-secondary table-sort-icon" />
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'vertragspartner1desc'" class="text-secondary table-sort-icon" />

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('vertragspartner1asc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'vertragspartner1asc'" />
                </div>
                <div class="col">A - Z</div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('vertragspartner1desc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'vertragspartner1desc'" />
                </div>
                <div class="col">Z - A</div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-4 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Vertragspartner 2</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'vertragspartner2asc'" class="text-secondary table-sort-icon" />
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'vertragspartner2desc'" class="text-secondary table-sort-icon" />

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('vertragspartner2asc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'vertragspartner2asc'" />
                </div>
                <div class="col">A - Z</div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('vertragspartner2desc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'vertragspartner2desc'" />
                </div>
                <div class="col">Z - A</div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-2 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Datum</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'datumasc'" class="text-secondary table-sort-icon" />
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'datumdesc'" class="text-secondary table-sort-icon" />

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('datumasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'datumasc'" />
                </div>
                <div class="col">A - Z</div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('datumdesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'datumdesc'" />
                </div>
                <div class="col">Z - A</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <VertragRow v-for="vertrag in sortedVertraege" :key="vertrag.id" :vertrag="vertrag" />
  </div>

  <div v-else-if="!loading && errorMsg == '' && vertraege.length == 0" class="alert alert-info mt-5">Es wurden keine Vorlagen gefunden.</div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';

  import { ActionTypes as VertragActionTypes } from '@/store/modules/Vertraege/actions';
  import { ActionTypes as PersonalActionTypes } from '@/store/modules/Personaldaten/actions';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import VertragRow from '@/components/Vertrag/VertragRow.vue';

  import { Vertrag } from '@/models/VertragModels';
  import { Personaldaten, GetPersonaldatenRequest } from '@/models/PersonaldatenModels';
  //#endregion
  const store = useStore();

  const loading = computed(
    () => store.getters.status.getVertraegeLoading || store.getters.status.getVorlageLoading || store.getters.status.getPersonaldatenLoading
  );
  const errorMsg = computed(() => store.getters.status.vertragErrorMsg);

  const personaldaten = computed(() => store.getters.personaldaten);

  function getPersonaldaten() {
    store.dispatch(PersonalActionTypes.GetPersonaldaten, new GetPersonaldatenRequest(null, null));
  }

  function getMitarbeiterByGuid(guid: string) {
    return personaldaten.value.find((p: Personaldaten) => p.guid == guid);
  }

  const searchString = ref('');

  const sortByValue = ref('datumdesc');
  function sortBy(param: string) {
    sortByValue.value = param;
  }

  const vertraege = computed(() => store.getters.vertraege);
  const sortedVertraege = computed(() => {
    return vertraege.value
      .filter((v: Vertrag) => {
        // const mitarbeiter = getMitarbeiterByGuid(v.personaldatenGuid);
        // const fullName = mitarbeiter ? mitarbeiter.vorname + ' ' + mitarbeiter.name : '';
        // const reverseFullName = mitarbeiter ? mitarbeiter.name + ' ' + mitarbeiter.vorname : '';

        let vertragspartner1 = '';
        if (v.vertragspartei1 != null) {
          if (v.vertragspartei1.unternehmen != '') {
            vertragspartner1 += v.vertragspartei1.unternehmen + ' | ';
          }

          vertragspartner1 += v.vertragspartei1.vorname + ' ' + v.vertragspartei1.name;
        }

        let vertragspartner2 = '';
        if (v.vertragspartei2 != null) {
          if (v.vertragspartei2.unternehmen != '') {
            vertragspartner2 += v.vertragspartei2.unternehmen + ' | ';
          }

          if (v.personaldatenGuid != '') {
            vertragspartner2 += v.personaldatenGuid + ' | ';
          }

          vertragspartner2 += v.vertragspartei2.vorname + ' ' + v.vertragspartei2.name;
        }

        return (
          v.titel.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
          vertragspartner1.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
          vertragspartner2.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
          v.personaldatenGuid.trim().toLowerCase().includes(searchString.value.trim().toLowerCase())
        );
      })
      .sort((a: Vertrag, b: Vertrag) => {
        if (sortByValue.value == 'titelasc' || sortByValue.value == 'titeldesc') {
          var titleComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase());
          if (sortByValue.value == 'titeldesc') {
            titleComparison = -1 * titleComparison;
          }

          if (titleComparison == 0) {
            const mitarbeiterA = getMitarbeiterByGuid(a.personaldatenGuid);
            const fullNameA = mitarbeiterA ? mitarbeiterA.vorname + ' ' + mitarbeiterA.nachname : '';

            const mitarbeiterB = getMitarbeiterByGuid(b.personaldatenGuid);
            const fullNameB = mitarbeiterB ? mitarbeiterB.vorname + ' ' + mitarbeiterB.nachname : '';

            var nameComparison = fullNameA.trim().toLowerCase().localeCompare(fullNameB.trim().toLowerCase());
            return nameComparison;
          }

          return titleComparison;
        } else if (sortByValue.value == 'datumasc' || sortByValue.value == 'datumdesc') {
          // var datumComparison = a.adate.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase())
          var datumComparison = new Date(a.adate).getTime() - new Date(b.adate).getTime();
          if (sortByValue.value == 'datumdesc') {
            datumComparison = -1 * datumComparison;
          }

          if (datumComparison == 0) {
            const mitarbeiterA = getMitarbeiterByGuid(a.personaldatenGuid);
            const fullNameA = mitarbeiterA ? mitarbeiterA.vorname + ' ' + mitarbeiterA.nachname : '';

            const mitarbeiterB = getMitarbeiterByGuid(b.personaldatenGuid);
            const fullNameB = mitarbeiterB ? mitarbeiterB.vorname + ' ' + mitarbeiterB.nachname : '';

            var nameComparison = fullNameA.trim().toLowerCase().localeCompare(fullNameB.trim().toLowerCase());
            return nameComparison;
          }

          return datumComparison;
        } else if (sortByValue.value == 'vertragspartner1asc' || sortByValue.value == 'vertragspartner1desc') {
          let vertragspartnerA = '';
          if (a.vertragspartei1 != null) {
            if (a.vertragspartei1.unternehmen != '') {
              vertragspartnerA += a.vertragspartei1.unternehmen + ' | ';
            }

            vertragspartnerA += a.vertragspartei1.vorname + ' ' + a.vertragspartei1.name;
          }

          let vertragspartnerB = '';
          if (b.vertragspartei1 != null) {
            if (b.vertragspartei1.unternehmen != '') {
              vertragspartnerB += b.vertragspartei1.unternehmen + ' | ';
            }

            vertragspartnerB += b.vertragspartei1.vorname + ' ' + b.vertragspartei1.name;
          }

          var vertragspartner1Comparison = vertragspartnerA.trim().toLowerCase().localeCompare(vertragspartnerB.trim().toLowerCase());

          if (sortByValue.value == 'vertragspartner1desc') {
            vertragspartner1Comparison = -1 * vertragspartner1Comparison;
          }

          if (vertragspartner1Comparison == 0) {
            var datumComparison = new Date(a.adate).getTime() - new Date(b.adate).getTime();
            return datumComparison;
          }

          return vertragspartner1Comparison;
        } else if (sortByValue.value == 'vertragspartner2asc' || sortByValue.value == 'vertragspartner2desc') {
          let vertragspartnerA = '';
          if (a.vertragspartei2 != null) {
            if (a.vertragspartei2.unternehmen != '') {
              vertragspartnerA += a.vertragspartei2.unternehmen + ' | ';
            }

            if (a.personaldatenGuid != '') {
              vertragspartnerA += a.personaldatenGuid + ' | ';
            }

            vertragspartnerA += a.vertragspartei2.vorname + ' ' + a.vertragspartei2.name;
          }

          let vertragspartnerB = '';
          if (b.vertragspartei2 != null) {
            if (b.vertragspartei2.unternehmen != '') {
              vertragspartnerB += b.vertragspartei2.unternehmen + ' | ';
            }

            if (b.personaldatenGuid != '') {
              vertragspartnerB += b.personaldatenGuid + ' | ';
            }

            vertragspartnerB += b.vertragspartei2.vorname + ' ' + b.vertragspartei2.name;
          }

          var vertragspartner2Comparison = vertragspartnerA.trim().toLowerCase().localeCompare(vertragspartnerB.trim().toLowerCase());

          if (sortByValue.value == 'vertragspartner2desc') {
            vertragspartner2Comparison = -1 * vertragspartner2Comparison;
          }

          if (vertragspartner2Comparison == 0) {
            var datumComparison = new Date(a.adate).getTime() - new Date(b.adate).getTime();
            return datumComparison;
          }

          return vertragspartner2Comparison;
        }

        return 0;
      });
  });

  function loadVertraege() {
    store.dispatch(VertragActionTypes.GetVertraege, undefined).then(() => {
      if (personaldaten.value.length == 0) {
        getPersonaldaten();
      }
    });
  }

  onMounted(() => {
    loadVertraege();
  });
</script>

<style scoped>
  .table-col:hover {
    background: rgb(216, 217, 222);
    cursor: pointer;
  }

  .table-sort-icon {
    font-size: 0.7em;
  }

  .select-sort:hover {
    cursor: pointer;
  }
</style>
