import { createStore, createLogger } from 'vuex';

import { store as auth, AuthStore, State as AuthState} from '@/store/modules/Auth';
import { store as druckvorlagen, DruckvorlagenStore, State as DruckvorlagenState} from '@/store/modules/Druckvorlagen';
import { store as common, CommonStore, State as CommonState} from '@/store/modules/Common';
import { store as personaldaten, PersonaldatenStore, State as PersonaldatenState } from "@/store/modules/Personaldaten"
import { store as variablen, VariablenStore, State as VariablenState } from "@/store/modules/Variablen"
import { store as vorlagen, VorlagenStore, State as VorlagenState } from "@/store/modules/Vorlagen"
import { store as vertraege, VertraegeStore, State as VertraegeState } from "@/store/modules/Vertraege"
import { store as vertragspartner, VertragspartnerStore, State as VertragspartnerState } from "@/store/modules/Vertragspartner"


export type RootState = {
  auth: AuthState;
  druckvorlagen: DruckvorlagenState;
  common: CommonState;
  personaldaten: PersonaldatenState;
  variablen: VariablenState;
  vorlagen: VorlagenState;
  vertraege: VertraegeState;
  vertragspartner: VertragspartnerState;
};

export type Store = AuthStore<Pick<RootState, "auth">>
  & DruckvorlagenStore<Pick<RootState, 'druckvorlagen'>>
  & CommonStore<Pick<RootState, 'common'>>
  & PersonaldatenStore<Pick<RootState, 'personaldaten'>>
  & VariablenStore<Pick<RootState, 'variablen'>>
  & VorlagenStore<Pick<RootState, 'vorlagen'>>
  & VertraegeStore<Pick<RootState, 'vertraege'>>
  & VertragspartnerStore<Pick<RootState, 'vertragspartner'>>

const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({})] : [];

export const store = createStore({
  plugins,
  modules: {
    auth: auth,
    druckvorlagen: druckvorlagen,
    common: common,
    personaldaten: personaldaten,
    variablen: variablen,
    vorlagen: vorlagen,
    vertraege: vertraege,
    vertragspartner: vertragspartner,
  },
});

export function useStore(): Store {
  return store as Store;
}