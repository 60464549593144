<template>
  <div class="d-flex justify-content-between">
    <h1>Druckvorlagen</h1>

    <button class="btn" @click="loadDokumente()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="row mb-3">
    <div class="col-4">
      <EditDruckvorlageModal :index="-1" :buttonStyle="0" />
    </div>

    <div class="col-4">
      <div class="input-group input-group-sm my-auto">
        <input v-model.trim="searchString" type="search" class="form-control" id="searchInput" placeholder="Suche...">
        <span class="input-group-text">
          <font-awesome-icon icon="magnifying-glass" class="p-1"/>
        </span>
      </div>
    </div>
  </div>

  <LoadingSpinner v-if="loading" text="... Druckvorlagen werden geladen ..." />
  
  <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
    {{ errorMsg }}
    <!-- Es ist ein Fehler beim Laden der Vorlagen aufgetreten. Bite probieren Sie es später erneut. -->
  </div>

  <div v-if="!loading && druckvorlagen.length > 0" class="mt-2">

    <div class="row mx-1 mb-2 bg-white shadow-sm">
      <div class="col-3 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Titel</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'titelasc'" class="text-secondary table-sort-icon"/>
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'titeldesc'" class="text-secondary table-sort-icon"/>

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('titelasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'titelasc'" />
                </div>
                <div class="col">
                  A - Z
                </div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('titeldesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'titeldesc'" />
                </div>
                <div class="col">
                  Z - A
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-3 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Kategorie</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'kategorieasc'" class="text-secondary table-sort-icon"/>
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'kategoriedesc'" class="text-secondary table-sort-icon"/>

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('kategorieasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'kategorieasc'" />
                </div>
                <div class="col">
                  A - Z
                </div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('kategoriedesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'kategoriedesc'" />
                </div>
                <div class="col">
                  Z - A
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-6 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Datei</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'dateiasc'" class="text-secondary table-sort-icon"/>
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'dateidesc'" class="text-secondary table-sort-icon"/>

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('dateiasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'dateiasc'" />
                </div>
                <div class="col">
                  A - Z
                </div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('dateidesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'dateidesc'" />
                </div>
                <div class="col">
                  Z - A
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

    </div>

    <DruckvorlageRow v-for="druckvorlage in sortedDruckvorlagen" :key="druckvorlage.id" :druckvorlage="druckvorlage" />
  </div>

  <div v-else-if="!loading && errorMsg == '' && druckvorlagen.length == 0" class="alert alert-info mt-5">
    Es wurden keine Dokumente gefunden.
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';

import { ActionTypes as DokumenteActionTypes } from '@/store/modules/Druckvorlagen/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import DruckvorlageRow from '@/components/Druckvorlagen/DruckvorlageRow.vue';
import EditDruckvorlageModal from '@/components/Druckvorlagen/EditDruckvorlageModal.vue';

import { Druckvorlage } from '@/models/DruckvorlageModels';

export default defineComponent({
  name: "DruckvorlagenView",

  components: {
    LoadingSpinner,
    DruckvorlageRow,
    EditDruckvorlageModal
  },

  setup() {
    const store = useStore()

    const loading = computed(() => store.getters.status.getDruckvorlageLoading || store.getters.status.deleteDruckvorlageLoading)
    const errorMsg = computed(() => store.getters.status.druckvorlageErrorMsg)

    
    const searchString = ref("")

    const sortByValue = ref("titelasc")
    function sortBy (param: string) {
      sortByValue.value = param
    }

    const druckvorlagen = computed(() => store.getters.druckvorlagen)

    const sortedDruckvorlagen = computed(() => {
      return druckvorlagen.value
      .filter((druckvorlage: Druckvorlage) => {
        return druckvorlage.titel.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
            druckvorlage.kategorie.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
            druckvorlage.fileName.trim().toLowerCase().includes(searchString.value.trim().toLowerCase())
      })
      .sort((a: Druckvorlage, b: Druckvorlage) => {
        if (sortByValue.value == "titelasc" || sortByValue.value == "titeldesc") {
          var titelComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase())
          if (sortByValue.value == "titeldesc") {
            titelComparison = -1 * titelComparison
          }
          
          if (titelComparison == 0) {
            var kategorieComparison = a.kategorie.trim().toLowerCase().localeCompare(b.kategorie.trim().toLowerCase())
            return kategorieComparison
          }
          
          return titelComparison;
        }

        if (sortByValue.value == "kategorieasc" || sortByValue.value == "kategoriedesc") {
          var kategorieComparison = a.kategorie.trim().toLowerCase().localeCompare(b.kategorie.trim().toLowerCase())
          if (sortByValue.value == "kategoriedesc") {
            kategorieComparison = -1 * kategorieComparison
          }
          
          if (kategorieComparison == 0) {
            var titelComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase())
            return titelComparison
          }
          
          return kategorieComparison;
        }

        if (sortByValue.value == "dateiasc" || sortByValue.value == "dateidesc") {
          var dateiComparison = a.fileName.trim().toLowerCase().localeCompare(b.fileName.trim().toLowerCase())
          if (sortByValue.value == "dateidesc") {
            dateiComparison = -1 * dateiComparison
          }
          
          if (dateiComparison == 0) {
            var titelComparison = a.titel.trim().toLowerCase().localeCompare(b.titel.trim().toLowerCase())
            return titelComparison
          }
          
          return dateiComparison;
        }

        return 0;
      })
    })


    function loadDokumente() {
      store.dispatch(DokumenteActionTypes.GetDruckvorlagen, undefined)
    }

    return {
      loading,
      errorMsg,

      searchString,
      sortByValue,
      sortBy,

      druckvorlagen,
      sortedDruckvorlagen,

      loadDokumente
    }
  },

  mounted () {
    this.loadDokumente();
  }
})
</script>

<style scoped>
.table-col:hover {
  background: rgb(216, 217, 222);
  cursor: pointer;
}

.table-sort-icon {
  font-size: .7em;
}

.select-sort:hover {
  cursor: pointer;
}
</style>