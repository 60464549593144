import { Mandant, Personaldaten } from "../../../models/PersonaldatenModels";


export type State = {
  personaldaten: Array<Personaldaten>
  mandanten: Array<Mandant>
}

export const state: State = {
  personaldaten:  localStorage.getItem("personaldaten") != null && localStorage.getItem("personaldaten") !== undefined ? JSON.parse(localStorage.personaldaten) : new Array<Personaldaten>(),
  mandanten:  localStorage.getItem("mandanten") != null && localStorage.getItem("mandanten") !== undefined ? JSON.parse(localStorage.mandanten) : new Array<Mandant>(),
}
