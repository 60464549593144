import { AuthUser } from "@/models/AuthModels"
import { Configuration } from "@azure/msal-browser"

export type State = {
  authUser: AuthUser | null

  azureLogin: boolean;
  azureLoginAvailable: boolean;
  azureUsername: string
  msalConfig: Configuration | null,
  identityToken: String
}

export const state: State = {
  authUser:  localStorage.getItem("authUser") != null && localStorage.getItem("authUser") !== undefined ? JSON.parse(localStorage.authUser) : null,
  
  azureLogin: false,
  azureLoginAvailable: false,
  azureUsername: localStorage.getItem("azureusername") != null ? JSON.parse(localStorage.azureusername) : "",
  msalConfig: localStorage.getItem("msalconfig") != null ? JSON.parse(localStorage.msalconfig) : null,
  identityToken: ''
}
