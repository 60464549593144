<template>
  <div class="row mb-2 py-1 bg-white shadow-sm mx-1">
    <div class="col-2 my-auto">{{ personaldaten.name }}</div>
    <div class="col-2 my-auto">{{ personaldaten.vorname }}</div>
    <div class="col-7 my-auto">{{ personaldaten.guid }}</div>
    <div class="col-1 d-flex align-items-center justify-content-end"><router-link :to="{name: 'DetailsPersonaldaten', params: {guid: personaldaten.guid}}" class="btn btn-primary"><font-awesome-icon icon="chevron-right" /></router-link></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Personaldaten } from '../../models/PersonaldatenModels';


export default defineComponent({
  name: "PersonaldatenRow",

  props: {
    personaldaten: {
      type: Object as PropType<Personaldaten>,
      required: true
    }
  },

  setup(props) {
    
  }
})
</script>