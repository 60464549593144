<template>
  <div class="mb-3">
    <div v-if="input.variable != null">
      <div v-if="variableContentType == 'empty' || variableContentType == 'auto'">
        <TextInput
          v-if="input.variable.type == 'text'"
          :index="index"
          v-model="input.value"
          :label="input.variable.titel + ' (' + input.variable.platzhalter + ')'"
          :min="input.variable.minLength"
          :max="input.variable.maxLength"
          :inputType="input.variable.validationType"
        />

        <div v-else>
          <label :for="'input-' + index" class="form-label">{{ input.variable.titel }} ({{ input.variable.platzhalter }})</label>
          <input :type="input.variable.type" class="form-control" :id="'input-' + index" v-model="input.value" />
        </div>
      </div>

      <div v-else-if="variableContentType == 'select'">
        <label :for="'input-' + index" class="form-label">{{ input.variable.titel }} ({{ input.variable.platzhalter }})</label>
        <select class="form-select" :id="'input-' + index" v-model="input.value">
          <option value="">Option auswählen</option>
          <option v-for="value in input.variable.values" :key="value.id" :value="value.value">{{ value.value }}</option>
        </select>
      </div>
    </div>

    <div v-else class="alert alert-warning">Ungültige Variable (Variable ID {{ input.variableid }})</div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, PropType } from 'vue';

  import TextInput from '../FormInputs/TextInput.vue';

  import { Input } from '@/models/VertragModels';
  //#endregion

  const { input, index } = defineProps({
    input: {
      type: Object as PropType<Input>,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },
  });

  const variableContentType = computed(() => {
    if (input.variable != null) {
      if (input.variable.herkunft != '') {
        return 'auto';
      } else if (input.variable.values.length > 0) {
        return 'select';
      } else {
        return 'empty';
      }
    } else {
      return 'empty';
    }
  });
</script>
