<template>
  <div class="row mb-2 py-1 bg-white shadow-sm mx-1">
    <div class="col-3 my-auto">{{ druckvorlage.titel }}</div>
    <div class="col-3 my-auto">{{ druckvorlage.kategorie }}</div>
    <div class="col-5 my-auto">{{ druckvorlage.fileName }}</div>
    <div class="col-1 d-flex align-items-center justify-content-end">
      <button type="button" class="btn btn-secondary btn-sm me-1" @click="downloadDruckvorlage()">
        <font-awesome-icon icon="file-download" />
      </button>

      <EditDruckvorlageModal :druckvorlage="druckvorlage" :index="druckvorlage.id" :buttonStyle="1" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { useStore } from 'vuex';

import { ActionTypes as DokumenteActionTypes } from '@/store/modules/Druckvorlagen/actions';

import EditDruckvorlageModal from '@/components/Druckvorlagen/EditDruckvorlageModal.vue';

import { Druckvorlage, DownloadDruckvorlageRequest } from '../../models/DruckvorlageModels';


export default defineComponent({
  name: "DruckvorlageRow",

  components: {
    EditDruckvorlageModal
  },

  props: {
    druckvorlage: {
      type: Object as PropType<Druckvorlage>,
      required: true
    }
  },

  setup(props) {
    const store = useStore()

    function downloadDruckvorlage() {
      store.dispatch(DokumenteActionTypes.DownloadDruckvorlageById, new DownloadDruckvorlageRequest(props.druckvorlage.id, props.druckvorlage.fileName))
    }

    return {
      downloadDruckvorlage
    }
  }
})
</script>