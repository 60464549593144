<template>
  <button type="button" class="btn btn-outline-primary" @click="showModal()">Vertragspartner auswählen</button>

  <!-- Select Vertragspartner Modal -->
  <div
    class="modal fade"
    :id="'selectVertragspartnerModal-' + index"
    tabindex="-1"
    aria-labelledby="selectVertragspartnerModal"
    aria-hidden="true"
    ref="selectVertragspartnerModalRef"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary py-2">
          <strong class="modal-titel my-auto">Vertragspartner auswählen</strong>

          <button type="button" class="btn-close btn-sm text-white" @click="closeModal()"></button>
        </div>

        <div class="modal-body modal-body-min-height" id="selectVertragspartnerModalBody">
          <div class="row mb-3">
            <div class="col-4 offset-4">
              <div class="input-group input-group-sm my-auto">
                <input v-model.trim="searchString" type="search" class="form-control" id="searchInput" placeholder="Suche..." />
                <span class="input-group-text">
                  <font-awesome-icon icon="magnifying-glass" class="p-1" />
                </span>
              </div>
            </div>
          </div>

          <div class="row mx-1 mb-2 bg-white shadow-sm">
            <div class="col-5 py-2 table-col">
              <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <strong>Unternehmen</strong>

                <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'unternehmenasc'" class="text-secondary table-sort-icon" />
                <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'unternehmendesc'" class="text-secondary table-sort-icon" />

                <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
                  <li class="dropdown-item select-sort" @click="sortBy('unternehmenasc')">
                    <div class="row">
                      <div class="col-2">
                        <font-awesome-icon icon="check" v-show="sortByValue == 'unternehmenasc'" />
                      </div>
                      <div class="col">A - Z</div>
                    </div>
                  </li>
                  <li class="dropdown-item select-sort" @click="sortBy('unternehmendesc')">
                    <div class="row">
                      <div class="col-2">
                        <font-awesome-icon icon="check" v-show="sortByValue == 'unternehmendesc'" />
                      </div>
                      <div class="col">Z - A</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-3 py-2 table-col">
              <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <strong>Name</strong>

                <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'nameasc'" class="text-secondary table-sort-icon" />
                <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'namedesc'" class="text-secondary table-sort-icon" />

                <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
                  <li class="dropdown-item select-sort" @click="sortBy('nameasc')">
                    <div class="row">
                      <div class="col-2">
                        <font-awesome-icon icon="check" v-show="sortByValue == 'nameasc'" />
                      </div>
                      <div class="col">A - Z</div>
                    </div>
                  </li>
                  <li class="dropdown-item select-sort" @click="sortBy('namedesc')">
                    <div class="row">
                      <div class="col-2">
                        <font-awesome-icon icon="check" v-show="sortByValue == 'namedesc'" />
                      </div>
                      <div class="col">Z - A</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-4 py-2 table-col">
              <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <strong>Vorname</strong>

                <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'vornameasc'" class="text-secondary table-sort-icon" />
                <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'vornamedesc'" class="text-secondary table-sort-icon" />

                <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
                  <li class="dropdown-item select-sort" @click="sortBy('vornameasc')">
                    <div class="row">
                      <div class="col-2">
                        <font-awesome-icon icon="check" v-show="sortByValue == 'vornameasc'" />
                      </div>
                      <div class="col">A - Z</div>
                    </div>
                  </li>
                  <li class="dropdown-item select-sort" @click="sortBy('vornamedesc')">
                    <div class="row">
                      <div class="col-2">
                        <font-awesome-icon icon="check" v-show="sortByValue == 'vornamedesc'" />
                      </div>
                      <div class="col">Z - A</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div v-if="sortedVertragspartner.length == 0" class="alert alert-info mb-3">Es wurden keine Partner gefunden.</div>

          <div
            v-for="partner in sortedVertragspartner"
            :key="partner.id"
            class="row mx-1 mb-2 py-2 bg-white shadow-sm select-partner-row"
            @click="selectPartner(partner.id)"
          >
            <div class="col-5">{{ partner.unternehmen }}</div>
            <div class="col-3">{{ partner.name }}</div>
            <div class="col-4">{{ partner.vorname }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { PropType, ref, computed } from 'vue';
  import { Modal } from 'bootstrap';
  import { useStore } from '@/store';

  import { Vertragspartner } from '@/models/VertragspartnerModels';
  //#endregion

  const { index } = defineProps({
    index: {
      type: Number as PropType<number>,
      default: 0,
    },
  });

  const emit = defineEmits(['select-partner']);

  const store = useStore();

  const searchString = ref('');
  const sortByValue = ref('unternehmenasc');
  function sortBy(param: string) {
    sortByValue.value = param;
  }

  const vertragspartner = computed(() => store.getters.vertragspartner);
  const sortedVertragspartner = computed(() => {
    return vertragspartner.value
      .filter((v: Vertragspartner) => {
        return (
          v.unternehmen.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
          v.name.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
          v.vorname.trim().toLowerCase().includes(searchString.value.trim().toLowerCase())
        );
      })
      .sort((a: Vertragspartner, b: Vertragspartner) => {
        if (sortByValue.value == 'unternehmenasc' || sortByValue.value == 'unternehmendesc') {
          var unternehmenComparison = a.unternehmen.trim().toLowerCase().localeCompare(b.unternehmen.trim().toLowerCase());
          if (sortByValue.value == 'unternehmendesc') {
            unternehmenComparison = -1 * unternehmenComparison;
          }

          if (unternehmenComparison == 0) {
            var nameComparison = a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase());
            return nameComparison;
          }

          return unternehmenComparison;
        } else if (sortByValue.value == 'nameasc' || sortByValue.value == 'namedesc') {
          var nameComparison = a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase());

          if (sortByValue.value == 'namedesc') {
            nameComparison = -1 * nameComparison;
          }

          if (nameComparison == 0) {
            var vornameComparison = new Date(a.vorname).getTime() - new Date(b.vorname).getTime();
            return vornameComparison;
          }

          return nameComparison;
        } else if (sortByValue.value == 'vornameasc' || sortByValue.value == 'vornamedesc') {
          var vornameComparison = a.vorname.trim().toLowerCase().localeCompare(b.vorname.trim().toLowerCase());
          if (sortByValue.value == 'vornamedesc') {
            vornameComparison = -1 * vornameComparison;
          }

          if (vornameComparison == 0) {
            var nameComparison = new Date(a.name).getTime() - new Date(b.name).getTime();
            return nameComparison;
          }

          return vornameComparison;
        }

        return 0;
      });
  });

  function selectPartner(partnerid: number) {
    emit('select-partner', index, partnerid);
    closeModal();
  }

  //#region Modal
  const selectVertragspartnerModalRef = ref();

  function closeModal() {
    Modal.getInstance(selectVertragspartnerModalRef.value)?.hide();
  }

  function showModal() {
    if (selectVertragspartnerModalRef.value) {
      new Modal(selectVertragspartnerModalRef.value).show();
    }
  }
  //#endregion
</script>

<style scoped>
  .table-col:hover {
    background: rgb(216, 217, 222);
    cursor: pointer;
  }

  .table-sort-icon {
    font-size: 0.7em;
  }

  .select-sort:hover {
    cursor: pointer;
  }

  .select-partner-row:hover {
    background: #fff4af !important;
    cursor: pointer;
  }
</style>
