
export class LoginRequest {
  username: string
  userpassword: string
  secureBrowserToken: string

  constructor(username: string, userpassword: string, secureBrowserToken: string) {
    this.username = username;
    this.userpassword = userpassword;
    this.secureBrowserToken = secureBrowserToken;
  }
}

export class RefreshLoginRequest {
  refreshToken: string

  constructor(refreshToken: string) {
    this.refreshToken = refreshToken;
  }
}

export class LogoutRequest {
  refreshToken: string

  constructor(refreshToken: string) {
    this.refreshToken = refreshToken;
  }
}

export class AuthUser {
  // benutzer: Benutzer;
  benutzername: string;
  identityUserId: number;
  token: string;
  refreshToken: string;

  constructor() {
    // this.benutzer = new Benutzer();
    this.benutzername = "";
    this.identityUserId = 0;
    this.token = "";
    this.refreshToken = "";
  }
}

export interface AzureConfigEinstellung {
  clientId: string
  authority: string
  redirectUri: string
}

export class LoginAzureRequest {
  idToken: string
  username: string

  constructor (idToken: string, username: string) {
    this.idToken = idToken
    this.username = username
  }
}