<template>
  <div class="d-flex justify-content-between">
    <h1>Mitarbeiter</h1>

    <button class="btn" @click="getPersonaldaten()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="d-flex justify-content-between mb-4 bg-white shadow-sm p-2">
    <div class="d-flex align-items-center">
      <label for="mandant-select" class="me-2"><strong>Mandant:</strong></label>

      <select id="mandant-select" class="form-select" aria-label="Default select example" v-model="selectedMandant" @change="selectedMandantChanged()">
        <option :value="null">Mandant auswählen</option>
        <option v-for="mandant in mandanten" :key="mandant.id" :value="mandant">{{ mandant.man }}</option>
      </select>
    </div>

    <div class="col-4 offset-4">
      <div class="input-group input-group-sm my-auto">
        <input v-model.trim="searchString" type="search" class="form-control" id="searchInput" placeholder="Suche...">
        <span class="input-group-text">
          <font-awesome-icon icon="magnifying-glass" class="p-1"/>
        </span>
      </div>
    </div>
  </div>

  <div class="row mx-1 mb-2 bg-white shadow-sm">
    <div class="col-2 py-2 table-col">
      <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        <strong>Name</strong>

        <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'nameasc'" class="text-secondary table-sort-icon"/>
        <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'namedesc'" class="text-secondary table-sort-icon"/>

        <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
          <li class="dropdown-item select-sort" @click="sortBy('nameasc')">
            <div class="row">
              <div class="col-2">
                <font-awesome-icon icon="check" v-show="sortByValue == 'nameasc'" />
              </div>
              <div class="col">
                A - Z
              </div>
            </div>
          </li>
          <li class="dropdown-item select-sort" @click="sortBy('namedesc')">
            <div class="row">
              <div class="col-2">
                <font-awesome-icon icon="check" v-show="sortByValue == 'namedesc'" />
              </div>
              <div class="col">
                Z - A
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-2 py-2 table-col">
      <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        <strong>Vorname</strong>

        <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'vornameasc'" class="text-secondary table-sort-icon"/>
        <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'vornamedesc'" class="text-secondary table-sort-icon"/>

        <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
          <li class="dropdown-item select-sort" @click="sortBy('vornameasc')">
            <div class="row">
              <div class="col-2">
                <font-awesome-icon icon="check" v-show="sortByValue == 'vornameasc'" />
              </div>
              <div class="col">
                A - Z
              </div>
            </div>
          </li>
          <li class="dropdown-item select-sort" @click="sortBy('vornamedesc')">
            <div class="row">
              <div class="col-2">
                <font-awesome-icon icon="check" v-show="sortByValue == 'vornamedesc'" />
              </div>
              <div class="col">
                Z - A
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-8 py-2 table-col">
      <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        <strong>Guid</strong>

        <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'guidasc'" class="text-secondary table-sort-icon"/>
        <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'guiddesc'" class="text-secondary table-sort-icon"/>

        <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
          <li class="dropdown-item select-sort" @click="sortBy('guidasc')">
            <div class="row">
              <div class="col-2">
                <font-awesome-icon icon="check" v-show="sortByValue == 'guidasc'" />
              </div>
              <div class="col">
                A - Z
              </div>
            </div>
          </li>
          <li class="dropdown-item select-sort" @click="sortBy('guiddesc')">
            <div class="row">
              <div class="col-2">
                <font-awesome-icon icon="check" v-show="sortByValue == 'guiddesc'" />
              </div>
              <div class="col">
                Z - A
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <LoadingSpinner v-if="mandantenLoading || personaldatenLoading" text="... Daten werden geladen ..." />
  
  <PersonaldatenRow v-for="pers in sortedPersonaldaten" :key="pers.id" :personaldaten="pers" />


  <div v-if="(!mandantenLoading && mandanten.length == 0) || (!personaldatenLoading && personaldaten.length == 0)" class="alert alert-info mt-5">
    Es wurden keine Daten gefunden.
  </div>

  <div v-else-if="mandantenErrorMsg != '' || personaldatenErrorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler beim Laden der Daten aufgetreten. Bite probieren Sie es später erneut.
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from '@/store'
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personaldaten/actions";

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { GetPersonaldatenRequest, Mandant, Personaldaten } from "@/models/PersonaldatenModels";

import PersonaldatenRow from "@/components/Personaldaten/PersonaldatenRow.vue"

export default defineComponent({
  name: "ArtikelView",

  components: {
    LoadingSpinner,
    PersonaldatenRow
  },


  setup () {
    const store = useStore();

    const personaldatenLoading = computed(() => store.getters.status.getPersonaldatenLoading)
    const personaldatenErrorMsg = computed(() => store.getters.status.personaldatenErrorMsg)

    const mandantenLoading = computed(() => store.getters.status.getMandantenLoading)
    const mandantenErrorMsg = computed(() => store.getters.status.mandantenErrorMsg)

    const mandanten = computed(() => {
      const uniqueMAN = new Set<string>()

      const filteredArray = store.getters.mandanten.reduce((acc: Mandant[], mandant: Mandant) => {
        // Check if 'man' is already in the Set
        if (!uniqueMAN.has(mandant.man)) {
          // If not, add 'man' to the Set and push the object to the accumulator
          uniqueMAN.add(mandant.man);
          acc.push(mandant)
        }

        return acc;
      }, []);

      return filteredArray
    })
    // const selectedMandant = ref<Mandant>()

    const selectedMandantStore = ref<Mandant>()
    const selectedMandant = computed({
      get () {
        return selectedMandantStore.value
      },

      set (value) {
        // selectedMandant.value = value
        selectedMandantStore.value = value;
        sessionStorage.setItem("selectedMandant", JSON.stringify(value))
      }
    })


    const searchString = ref("")

    const sortByValue = ref("nameasc")
    function sortBy (param: string) {
      sortByValue.value = param
    }

    const personaldaten = computed(() => {
      if (selectedMandant.value != null) {
        return store.getters.personaldaten.filter((p: Personaldaten) => p.mandant == selectedMandant.value?.man)
      }
      else {
        return store.getters.personaldaten
      }
    });

    const sortedPersonaldaten = computed(() => {
      return personaldaten.value
      .filter((p: Personaldaten) => {
        const fullName = p.vorname + " " + p.name
        const reverseFullName = p.name + " " + p.vorname
        return fullName.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
              reverseFullName.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
              p.guid.trim().toLowerCase().includes(searchString.value.trim().toLowerCase())
      })
      .sort((a: Personaldaten, b: Personaldaten) => {
        if (sortByValue.value == "nameasc" || sortByValue.value == "namedesc") {
          var nameComparison = a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase())
          if (sortByValue.value == "namedesc") {
            nameComparison = -1 * nameComparison
          }
          
          if (nameComparison == 0) {
            var vornameComparison = a.vorname.trim().toLowerCase().localeCompare(b.vorname.trim().toLowerCase())
            return vornameComparison
          }
          
          return nameComparison;
        }

        if (sortByValue.value == "vornameasc" || sortByValue.value == "vornamedesc") {
          var vornameComparison = a.vorname.trim().toLowerCase().localeCompare(b.vorname.trim().toLowerCase())
          if (sortByValue.value == "vornamedesc") {
            vornameComparison = -1 * vornameComparison
          }
          
          if (vornameComparison == 0) {
            var nameComparison = a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase())
            return nameComparison
          }
          
          return vornameComparison;
        }

        if (sortByValue.value == "guidasc" || sortByValue.value == "guiddesc") {
          var guidComparison = a.guid.trim().toLowerCase().localeCompare(b.guid.trim().toLowerCase())
          if (sortByValue.value == "guiddesc") {
            guidComparison = -1 * guidComparison
          }
          
          return guidComparison;
        }

        return 0;
      })
    })

    return {
      store,

      personaldatenLoading,
      personaldatenErrorMsg,
      
      mandantenLoading,
      mandantenErrorMsg,
      
      mandanten,
      
      selectedMandant,
      
      searchString,
      sortByValue,
      sortBy,

      personaldaten,
      sortedPersonaldaten
    }
  },

  methods: {
    loadData () {
      this.store.dispatch(PersonalActionTypes.GetMandanten, undefined)
      .then(() => {
        if (this.mandantenErrorMsg == "" && this.mandanten.length > 0) {
          this.selectedMandant = this.mandanten[0]

          this.store.dispatch(PersonalActionTypes.GetPersonaldaten, new GetPersonaldatenRequest(this.selectedMandant.man, null))
        }
      })
    },

    selectedMandantChanged() {
      // if (this.personaldaten.length == 0) {
        this.getPersonaldaten();
      // }
    },

    getPersonaldaten() {
      this.store.dispatch(PersonalActionTypes.GetPersonaldaten, new GetPersonaldatenRequest(this.selectedMandant?.man || null, null))
    }

  },

  mounted () {
    

    if (this.mandanten.length == 0) {
      this.loadData();
    }
    else {
      if (sessionStorage.getItem("selectedMandant") != undefined) {
        this.selectedMandant = JSON.parse(sessionStorage.selectedMandant) as Mandant
      }
      else {
        this.selectedMandant = this.mandanten[0]
      }

      if (this.personaldaten.length == 0) {
        this.getPersonaldaten();
      }
    }
  }

})
</script>

<style scoped>

.status-anzeige {
  position: relative;
  margin-top: -10px;
  margin-right: 5px;
  font-size: .8em;
  text-align: end;
}

.table-col:hover {
  background: rgb(216, 217, 222);
  cursor: pointer;
}

.table-sort-icon {
  font-size: .7em;
}

.select-sort:hover {
  cursor: pointer;
}
</style>