<template>
  <div class="row mb-2 py-1 bg-white shadow-sm mx-1">
    <div class="col-5 my-auto">{{ vertragspartner.unternehmen }}</div>
    <div class="col-3 my-auto">{{ vertragspartner.name }}</div>
    <div class="col-3 my-auto">{{ vertragspartner.vorname }}</div>
    <div class="col-1 d-flex align-items-center justify-content-end"><router-link :to="{name: 'DetailsVertragspartner', query: {vertragspartnerid: vertragspartner.id}}" class="btn btn-primary"><font-awesome-icon icon="chevron-right" /></router-link></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Vertragspartner } from '../../models/VertragspartnerModels';


export default defineComponent({
  name: "VertragspartnerRow",

  props: {
    vertragspartner: {
      type: Object as PropType<Vertragspartner>,
      required: true
    }
  },

  setup(props) {
    
  }
})
</script>