export class Vertragspartner {
  id: number;
  name: string;
  vorname: string;
  unternehmen: string;
  strasse: string;
  plz: string;
  ort: string;
  land: string;
  notizen: string;

  constructor() {
    this.id = 0;
    this.name = '';
    this.vorname = '';
    this.unternehmen = '';
    this.strasse = '';
    this.plz = '';
    this.ort = '';
    this.land = '';
    this.notizen = '';
  }
}