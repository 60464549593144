<template>
  <div class="mx-1 mb-2 p-2 bg-white shadow">
    <div class="row">
      <div class="col-3 fw-bold my-auto">{{ textbaustein.name }}</div>
      <div class="col-6 my-auto">{{ textbaustein.titel }}</div>

      <div class="col-1 my-auto">{{ abschnitteCount }}</div>

      <div class="col-2 my-auto d-flex justify-content-end">
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-primary" @click="toggleCollapse()"><font-awesome-icon icon="magnifying-glass-plus" /></button>
          <router-link v-if="showEdit" :to="{ name: 'EditTextbaustein', query: { textbausteinid: textbaustein.id } }" class="btn btn-secondary">
            <font-awesome-icon icon="pen" />
          </router-link>
        </div>
      </div>
    </div>

    <div class="mt-3 pt-2 border-top border-secondary" :class="{ collapse: isCollapsed }">
      <p class="fw-bold">{{ textbaustein.titel }}</p>

      <div class="content-text">
        <div v-html="textbaustein.text"></div>
      </div>

      <div v-for="abschnitt in textbaustein.abschnitte" :key="abschnitt.id" class="mt-3">
        <p v-if="abschnitt.titel != ''" class="fw-bold">{{ abschnitt.titel }}</p>
        <div class="content-text">
          <div v-html="abschnitt.text"></div>

          <div v-for="subAbschnitt in abschnitt.abschnitte" :key="subAbschnitt.id" class="mt-3">
            <p v-if="subAbschnitt.titel != ''" class="fw-bold fst-italic">{{ subAbschnitt.titel }}</p>
            <div class="content-text">
              <div v-html="subAbschnitt.text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { PropType, computed, defineComponent, ref } from 'vue';
  import LoadingSpinner from '../LoadingSpinner.vue';
  import TextEditor from '../TextEditor/TextEditor.vue';

  import { Textbaustein } from '@/models/VorlageModels';

  export default defineComponent({
    name: 'TextbausteinRow',

    components: {
      LoadingSpinner,
      TextEditor,
    },

    props: {
      textbaustein: {
        type: Object as PropType<Textbaustein>,
        required: true,
      },

      showEdit: {
        type: Boolean,
        default: true,
      },
    },

    setup(props) {
      const isCollapsed = ref(true);
      function toggleCollapse() {
        isCollapsed.value = !isCollapsed.value;
      }

      const abschnitteCount = computed(() => {
        return props.textbaustein.abschnitte.length > 0 ? props.textbaustein.abschnitte.length : '-';
      });

      return {
        isCollapsed,
        toggleCollapse,

        abschnitteCount,
      };
    },
  });
</script>
